<template>
  <v-container>
    <div class="my-3">
      <div class="text-h4">Porque nos preocupamos por ti </div>
      <div class="">Seguimiento semanal de salud</div>
    </div>
    <v-card v-if="!encuestaModelo.id">
        <v-card-text class="text-center">
            <div v-if="controlador.permisos">
               <div>Buscando encuesta</div>
                <div>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                </div> 
            </div>
            <div v-else>
                <div class="text-h4">¡Felicitaciones! <br /> Usted no tiene pendiente su <b> encuesta semanal</b></div>
            </div>
            
        </v-card-text>
        
    </v-card>
    <v-stepper v-model="paso" vertical v-else>
        <v-stepper-step step="1" :editable="paso > 1" :complete="paso > 1">Medicación</v-stepper-step>
        <v-stepper-content step="1">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        ¿Cumplió con la medicación indicada en horario y dosis en la última semana?
                    </v-card>
                </v-col>
                <v-col>
                    <div class="saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded width="100%"
                            :outlined="encuesta.medicacion.respuesta.opcion_ref !== 382"  
                            @click="fillAns('medicacion', 382, 1, true)">SI, he cumplido</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark  width="100%"
                            :outlined="encuesta.medicacion.respuesta.opcion_ref !== 383"  
                            @click="fillAns('medicacion', 383, 0, true)">NO, no he cumplido</v-btn></div>
                    </div>
                    
                    
                </v-col>
            </v-row>
        </v-stepper-content>

        <v-stepper-step :step="(1 + aumento_diabetes)" :editable="paso > (1 + aumento_diabetes)" :complete="paso > (1 + aumento_diabetes)">Medicación</v-stepper-step>
        <v-stepper-content :step="(1 + aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        ¿Realizó su hemoglucotest como indicado por su médico en la última semana? 
                    </v-card>
                </v-col>
                <v-col>
                    <div class="saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.hemoglucotest.respuesta.opcion_ref !== 384"  
                            @click="fillAns('hemoglucotest', 384, 1, true)">SI, he realizado</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark  width="100%"
                            :outlined="encuesta.hemoglucotest.respuesta.opcion_ref !== 385"  
                            @click="fillAns('hemoglucotest', 385, 0, true)">NO, no he realizado</v-btn></div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>
        

        <v-stepper-step :step="(2 + aumento_diabetes)" :editable="paso > (2 + aumento_diabetes)" :complete="paso > (2 + aumento_diabetes)"> {{encuesta.presion.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(2+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.presion.pregunta.text}}
                    </v-card>
                </v-col>
                <v-col>
                    <div class="saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.presion.respuesta.opcion_ref !== 386"  
                            @click="fillAns('presion', 386, 1)">SI, he realizado</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.presion.respuesta.opcion_ref !== 387"  
                            @click="fillAns('presion', 387, 0, true); fillAns('presion_control', 388, 0, false)">NO, no he realizado</v-btn></div>
                        <div class="mt-2" v-if="encuesta.presion.respuesta.opcion_ref === 386" style="overflow:auto">
                            <div >Por favor especifique</div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark 
                                :outlined="encuesta.presion.respuesta.opcion_ref !== 389"  
                                @click="fillAns('presion_control', 389, 1, true)">En media fue mayor que 140 x 90 mmHg</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark
                                :outlined="encuesta.presion.respuesta.opcion_ref !== 390"  
                                @click="fillAns('presion_control', 390, 1, true)">En media fue menor que 140 x 90 mmHg   </v-btn></div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>
        

        <v-stepper-step :step="(3 + aumento_diabetes)" :editable="paso > (3 + aumento_diabetes)" :complete="paso > (3 + aumento_diabetes)"> {{encuesta.actividad.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(3+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="4">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.actividad.pregunta.text}}
                    </v-card>
                    <div class="text-center saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad.respuesta.opcion_ref !== 391"  
                            @click="fillAns('actividad', 391, 1);">SI, he realizado</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark  width="100%"
                            :outlined="encuesta.actividad.respuesta.opcion_ref !== 392"  
                            @click="fillAns('actividad', 392, 1, true);fillAns('actividad_si', 393, 0, false);fillAns('actividad_minutos', 398, 0, false); encuesta.actividad.dias=false;">NO, no he realizado</v-btn></div>
                    </div>
                    
                </v-col>
                <v-col cols="12" md="4">
                    
                    <div class="mt-2 saludable-quest mx-auto" v-if="encuesta.actividad.respuesta.opcion_ref === 391">
                        <div>Por favor especifique</div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_si.respuesta.opcion_ref !== 394"  
                            @click="fillAns('actividad_si', 394, 1, false); encuesta.actividad.dias=true;">rara vez (1 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_si.respuesta.opcion_ref !== 395"  
                            @click="fillAns('actividad_si', 395, 1, false); encuesta.actividad.dias=true;">algunas veces (2 a 3 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_si.respuesta.opcion_ref !== 396"  
                            @click="fillAns('actividad_si', 396, 1, false); encuesta.actividad.dias=true;">Muchas veces (4 a 5 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_si.respuesta.opcion_ref !== 397"  
                            @click="fillAns('actividad_si', 397, 1, false); encuesta.actividad.dias=true;">Siempre (6 a 7 x / Sem) </v-btn></div>
                    </div>
                </v-col>
                
                <v-col v-if="encuesta.actividad.dias">
                    <div class="mt-2" v-if="encuesta.actividad.respuesta.opcion_ref === 391">
                        <div>Por cuanto tiempo hace actividad física</div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_minutos.respuesta.opcion_ref !== 399"  
                            @click="fillAns('actividad_minutos', 399, 1, true)">5 a 10 min</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_minutos.respuesta.opcion_ref !== 400"  
                            @click="fillAns('actividad_minutos', 400, 1, true)">10 a 20 min</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_minutos.respuesta.opcion_ref !== 401"  
                            @click="fillAns('actividad_minutos', 401, 1, true)">21 a 31 min</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.actividad_minutos.respuesta.opcion_ref !== 402"  
                            @click="fillAns('actividad_minutos', 402, 1, true)">30 a 60 min </v-btn></div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>


        <v-stepper-step :step="(4 + aumento_diabetes)" 
            :editable="paso > (4 + aumento_diabetes)" 
            :complete="paso > (4 + aumento_diabetes)"> {{encuesta.alimentos_buenos.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(4+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.alimentos_buenos.pregunta.text}}
                    </v-card>
                </v-col>
                <v-col>
                    <div class="saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded  dark width="100%"
                            :outlined="encuesta.alimentos_buenos.respuesta.opcion_ref !== 403"  
                            @click="fillAns('alimentos_buenos', 403, 1)">SI, si he consumido</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.alimentos_buenos.respuesta.opcion_ref !== 404"  
                            @click="fillAns('alimentos_buenos', 404, 1, true); fillAns('alimentos_buenos_cantidad', 405, 1, false)">NO, no he consumido</v-btn></div>
                        
                        <div class="mt-2" v-if="encuesta.alimentos_buenos.respuesta.opcion_ref === 403">
                            <div>Por favor especifique</div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_buenos_cantidad.respuesta.opcion_ref !== 406"  
                                @click="fillAns('alimentos_buenos_cantidad', 406, 1, true)">rara vez (1 x / Sem)</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_buenos_cantidad.respuesta.opcion_ref !== 407"  
                                @click="fillAns('alimentos_buenos_cantidad', 407, 1, true)">algunas veces (2 a 3 x / Sem)</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_buenos_cantidad.respuesta.opcion_ref !== 408"  
                                @click="fillAns('alimentos_buenos_cantidad', 408, 1, true)">Muchas veces (4 a 5 x / Sem)</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_buenos_cantidad.respuesta.opcion_ref !== 409"  
                                @click="fillAns('alimentos_buenos_cantidad', 409, 1, true)">Siempre (6 a 7 x / Sem) </v-btn></div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>


        <v-stepper-step :step="(5 + aumento_diabetes)" 
            :editable="paso > (5 + aumento_diabetes)" 
            :complete="paso > (5 + aumento_diabetes)"> {{encuesta.alimentos_malos.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(5+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.alimentos_malos.pregunta.text}}
                    </v-card>
                </v-col>
                <v-col>
                    <div class="saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded 
                            :outlined="encuesta.alimentos_malos.respuesta.opcion_ref !== 410"  width="100%"
                            @click="fillAns('alimentos_malos', 410, 1)">SI, he consumido</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark
                            :outlined="encuesta.alimentos_malos.respuesta.opcion_ref !== 411"  width="100%"
                            @click="fillAns('alimentos_malos', 411, 1, true); fillAns('alimentos_malos_cantidad', 412, 1, false);">NO, no he consumido</v-btn></div>
                        
                        <div class="mt-2" v-if="encuesta.alimentos_malos.respuesta.opcion_ref === 410">
                            <div >Por favor especifique</div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_malos_cantidad.respuesta.opcion_ref !== 413"  
                                @click="fillAns('alimentos_malos_cantidad', 413, 1, true)">4 a 5 elementos</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_malos_cantidad.respuesta.opcion_ref !== 414"  
                                @click="fillAns('alimentos_malos_cantidad', 414, 1, true)">3 elementos</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_malos_cantidad.respuesta.opcion_ref !== 415"  
                                @click="fillAns('alimentos_malos_cantidad', 415, 1, true)">2 elementos</v-btn></div>
                            <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                                :outlined="encuesta.alimentos_malos_cantidad.respuesta.opcion_ref !== 416"  
                                @click="fillAns('alimentos_malos_cantidad', 416, 1, true)">1 elemento </v-btn></div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>


        <v-stepper-step :step="(6 + aumento_diabetes)" 
            :editable="paso > (6 + aumento_diabetes)" 
            :complete="paso > (6 + aumento_diabetes)"> {{encuesta.reposo.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(6+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.reposo.pregunta.text}}
                    </v-card>
                </v-col>
                <v-col>
                    
                    <div class="mt-2 saludable-quest mx-auto">
                        
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.reposo.respuesta.opcion_ref !== 417"  
                            @click="fillAns('reposo', 417, 1, true)">rara vez (1 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.reposo.respuesta.opcion_ref !== 418"  
                            @click="fillAns('reposo', 418, 1, true)">algunas veces (2 a 3 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.reposo.respuesta.opcion_ref !== 419"  
                            @click="fillAns('reposo', 419, 1, true)">Muchas veces (4 a 5 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.reposo.respuesta.opcion_ref !== 420"  
                            @click="fillAns('reposo', 420, 1, true)">Siempre (6 a 7 x / Sem) </v-btn></div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>
        

        <v-stepper-step :step="(7 + aumento_diabetes)" 
            :editable="paso > (7 + aumento_diabetes)" 
            :complete="paso > (7 + aumento_diabetes)"> {{encuesta.gaseosa.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(7+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="4">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.gaseosa.pregunta.text}}
                    </v-card>
                    <div class="text-center">
                        <div class="mt-2"><v-btn color="primary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa.respuesta.opcion_ref !== 421"  
                            @click="fillAns('gaseosa', 421, 1);">SI, he realizado</v-btn></div>
                        <div class="mt-2"><v-btn color="red" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa.respuesta.opcion_ref !== 422"  
                            @click="fillAns('gaseosa', 422, 0, true); fillAns('gaseosa_dias', 423, 0, false); fillAns('gaseosa_vasos', 428, 0, false); encuesta.gaseosa.dias=false;">NO, no he realizado</v-btn></div>
                    </div>
                    
                </v-col>
                <v-col cols="12" md="4" v-if="encuesta.gaseosa.respuesta.opcion_ref === 421">
                    <div class="mt-2 saludable-quest mx-auto">
                        <div>Por favor especifique</div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_dias.respuesta.opcion_ref !== 424"  
                            @click="fillAns('gaseosa_dias', 424, 1, false); encuesta.gaseosa.dias=true;">rara vez (1 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_dias.respuesta.opcion_ref !== 425"  
                            @click="fillAns('gaseosa_dias', 425, 1, false); encuesta.gaseosa.dias=true;">algunas veces (2 a 3 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_dias.respuesta.opcion_ref !== 426"  
                            @click="fillAns('gaseosa_dias', 426, 1, false); encuesta.gaseosa.dias=true;">Muchas veces (4 a 5 x / Sem)</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_dias.respuesta.opcion_ref !== 427"  
                            @click="fillAns('gaseosa_dias', 427, 1, false); encuesta.gaseosa.dias=true;">Siempre (6 a 7 x / Sem) </v-btn></div>
                    </div>
                </v-col>
                
                <v-col v-if="encuesta.gaseosa.dias">
                    <div class="mt-2 saludable-quest mx-auto">
                        <div>Por cuanto tiempo hace actividad física</div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_vasos.respuesta.opcion_ref !== 429"  
                            @click="fillAns('gaseosa_vasos', 429, 1, true)">mas de 10 vasos</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_vasos.respuesta.opcion_ref !== 430"  
                            @click="fillAns('gaseosa_vasos', 430, 1, true)">Entre 6 y 9 vasos</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_vasos.respuesta.opcion_ref !== 431"  
                            @click="fillAns('gaseosa_vasos', 431, 1, true)">Entre 5 y 2 vasos</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.gaseosa_vasos.respuesta.opcion_ref !== 432"  
                            @click="fillAns('gaseosa_vasos', 432, 1, true)">Menos de 1 vaso</v-btn></div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>


        <v-stepper-step :step="(8 + aumento_diabetes)" 
            :editable="paso > (8 + aumento_diabetes)" 
            :complete="paso > (8 + aumento_diabetes)"> {{encuesta.agua.pregunta.title}}</v-stepper-step>
        <v-stepper-content :step="(8+aumento_diabetes)">
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="mx-auto my-2 px-4 py-4" elevation="5" max-width="400">
                        {{encuesta.agua.pregunta.text}}
                    </v-card>
                </v-col>
                <v-col>
                    <div class="mt-2  saludable-quest mx-auto">
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.agua.respuesta.opcion_ref !== 30"  
                            @click="fillAns('agua', 433, 1, true)">mas de 8 vasos</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.agua.respuesta.opcion_ref !== 40"  
                            @click="fillAns('agua', 434, 1, true)">7 vasos</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.agua.respuesta.opcion_ref !== 50"  
                            @click="fillAns('agua', 435, 1, true)">Entre 6 y 4</v-btn></div>
                        <div class="mt-2"><v-btn color="secondary" elevation="2" rounded dark width="100%"
                            :outlined="encuesta.agua.respuesta.opcion_ref !== 60"  
                            @click="fillAns('agua', 436, 1, true)">menos de 3 </v-btn></div>
                    </div>
                </v-col>
            </v-row>
        </v-stepper-content>
    </v-stepper>

    <div class="mt-4 mb-4">
        <div v-if="complete">
            <v-btn color="purple" elevation="2"  width="100%" dark @click="terminar()" > GUARDAR ENCUESTA </v-btn>
        </div> 
    </div>

    <v-dialog
        v-if="encuestaModelo.id"
        v-model="controlador.terminar"
        max-width="800"
        transition="dialog-transition"
    >
        <v-card>
            <v-card-text class="text-center" v-if="controlador.guardando">
                <div v-if="controlador.complete">
                    <div class="text-h3">Se ha guardado su encuesta semanal</div>
                    <div class="my-3">
                        <v-btn color="purple" dark to="/app/bienvenido">ir al inicio</v-btn>
                    </div>
                </div>
                <div v-else class="text-h3">Guardando contenido, por favor espere</div>
                
            </v-card-text>
            <v-card-text v-else>
                <div>
                    <div class="text-h5 pt-4">¿Seguro de guardar?</div>
                    <div class="mt-2" v-for="preg in encuestaModelo.preguntas.filter(a=>a.valido)" :key="preg.id">
                        <div class="font-weight-bold">{{preg.nombre}}</div>
                        <div v-for="res in preg.opciones.filter(a=>a.visible)" :key="res.id">RESPUESTA: {{res.nombre}}</div>
                    </div>
                </div>
                <div class="my-3">
                    <v-btn color="purple" width="100%" dark @click="guardar()">Guardar encuesta</v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>

  <br />
  <br />
  <br />
  </v-container>
</template>

<script>

import encuestaService from '@/service/hopeEncuesta'
import EncuestaSave from '@/model/app/pvs/Encuesta'
import cronicoService from '@/service/garantia/cronico'
export default {
    data(){return{
        encuestaEnviar:{},
        encuestaModelo:{},
        paso: 1,
        aumento_diabetes: 1,
        encuesta:{
            medicacion:{
                pregunta:{title:'Medicación', text:''},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 109 }
            },
            hemoglucotest:{
                pregunta:{id:1},
                respuesta:{ id:null, valor: 2, opcion_ref: 437, pregunta_id: 110 }
            },
            presion:{
                pregunta:{title:'Presion Arterial', text:'¿Controló su presión arterial en la última semana? '},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 111 }
            },
            presion_control:{
                pregunta:{title:'Presion Arterial Control', text:'¿Controló su presión arterial en la última semana? '},
                respuesta:{ id:null, valor: 0, opcion_ref: 388, pregunta_id: 112 }
            },
            actividad:{
                si:true, dias:false,
                pregunta:{title:'Actividad Física', text:'¿Realizó su actividad física de acuerdo con la indicación de su médico en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 113 }
            },
            actividad_si:{
                si:true, dias:false,
                pregunta:{title:'Actividad Física', text:'¿Realizó su actividad física de acuerdo con la indicación de su médico en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 393, pregunta_id: 114 }
            },
            actividad_minutos:{
                pregunta:{title:'x', text:'x'},
                respuesta:{ id:null, valor: 0, opcion_ref: 398, pregunta_id: 115 }
            },
            alimentos_buenos:{
                pregunta:{title:'Correcta Alimentación', text:'¿Consumió alimentos saludables (frijoles, cereales integrales, castañas, frutas y verduras) en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 116 }
            },
            alimentos_buenos_cantidad:{
                pregunta:{title:'Correcta Alimentación', text:'¿Consumió alimentos saludables (frijoles, cereales integrales, castañas, frutas y verduras) en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 405, pregunta_id: 117 }
            },
            alimentos_malos:{
                pregunta:{title:'Mala Alimentación', text:'¿Consumió alimentos no saludables (salchichas, galletas, frituras, refrescos y dulces de manera general) en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 118 }
            },
            alimentos_malos_cantidad:{
                pregunta:{title:'Mala Alimentación', text:'¿Consumió alimentos no saludables (salchichas, galletas, frituras, refrescos y dulces de manera general) en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 412, pregunta_id: 119 }
            },
            reposo:{
                pregunta:{title:'Descanso', text:'¿Usted durmió de 7 a 8 horas por noche y despertó descansado (a) y activo en la última semana? '},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 120 }
            },
            gaseosa:{
                dias:false,
                pregunta:{title:'Bebidas Azucaradas', text:'¿Usted consumió gaseosa o bebidas azucaradas en la última semana?  '},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 121 }
            },
            gaseosa_dias:{
                pregunta:{title:'Mala Alimentación', text:'¿Consumió alimentos no saludables (salchichas, galletas, frituras, refrescos y dulces de manera general) en la última semana?'},
                respuesta:{ id:null, valor: 0, opcion_ref: 423, pregunta_id: 122 }
            },
            gaseosa_vasos:{
                pregunta:{title:'x', text:'x'},
                respuesta:{ id:null, valor: 0, opcion_ref: 428, pregunta_id: 123 }
            },
            agua:{
                pregunta:{title:'Agua Pura', text:'¿Usted consumió cuántos vasos de agua de 300 ml diariamente en la última semana?  '},
                respuesta:{ id:null, valor: 0, opcion_ref: 0, pregunta_id: 124 }
            }
        },
        controlador:{
            permisos: false,
            terminar:false,
            guardando:false,
            complete:false
        }
    }},
    mounted(){
        this.encuestaEnviar = new EncuestaSave(6, this.$store.state.core.user);
        if(this.$store.getters["core/cghID"]){
            cronicoService.getPendiente(this.$store.getters["core/cghID"])
            .then(response => {
                response.data.forEach(enc => {
                    if(enc.tipo === "6"){
                        this.controlador.permisos = true;
                        encuestaService.getEncuesta(6)
                        .then(response => {
                            this.encuestaModelo = response.data;
                            this.encuestaModelo.preguntas.forEach(p => p.opciones.forEach(o => o.visible = false));
                        })
                    }
                });
            })
        }
        
    },
    methods:{
        fillAns(pregunta, id, valor, continuar){
            this.encuesta[pregunta].respuesta.valor = valor;
            this.encuesta[pregunta].respuesta.opcion_ref = id;
            if(continuar){
                this.paso = Number(this.paso) + 1;
            }
        },
        terminar(){
            this.controlador.terminar = true;
            for(var key in this.encuesta) {
                this.encuestaModelo.preguntas
                    .filter(p=>p.id === this.encuesta[key].respuesta.pregunta_id)
                    .forEach(w=>w.opciones
                        .filter(y=>y.id === this.encuesta[key].respuesta.opcion_ref)
                            .forEach(h=>h.visible = true))
            }
        },
        guardar(){
            this.controlador.guardando = true;
            this.controlador.complete = false;
            this.encuestaEnviar.detalles = [];
            for(var key in this.encuesta) {
                this.encuestaModelo.preguntas
                    .filter(p=>p.id === this.encuesta[key].respuesta.pregunta_id)
                    .forEach(w=>w.opciones
                        .filter(y=>y.id === this.encuesta[key].respuesta.opcion_ref)
                            .forEach(h=>{
                                this.encuestaEnviar.detalles.push({
                                    id:null,
                                    pregunta_id : this.encuesta[key].respuesta.pregunta_id,
                                    opcion_ref : this.encuesta[key].respuesta.opcion_ref,
                                    valor: h.valor
                                })
                            }))  
            }
            encuestaService.saveEncuestaRespuesta(this.encuestaEnviar)
            .then(() => {
                this.controlador.guardando = true;
                this.controlador.complete = true;
            });
        }
    },
    computed:{
        complete(){
            var isComplete = true;
            for(var key in this.encuesta) {
                if(this.encuesta[key].respuesta.opcion_ref === 0){
                    isComplete = false;
                   
                }
            }
            return isComplete;
        }
    }
}
</script>

<style >
    .saludable-quest{
        max-width: 300px;
    }
</style>