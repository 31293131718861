<template>
  <div>
    <v-row class="text-center">
        <v-col md="6">

        <div>ALTURA (cm)</div> 
        <div>
            <v-text-field :rules="validationRules.altura" filled  type="number"  v-model="imc.altura"></v-text-field>
        </div>
    
        <div>PESO (Kg)</div> 
        <div>
            <v-text-field filled  type="number" v-model="imc.peso"></v-text-field>
        </div>
    
        </v-col>
        <v-col cols="12" md="6">
            <v-card  v-if="!imcCorrect" color="warning" class="text-center" dark>
                <v-card-text>
                    <v-icon class="mt-2 text-h2">mdi-alert-circle-outline</v-icon>
                </v-card-text>
                <v-card-text class="text-h4">
                    Por favor complete correctamente el formulario
                </v-card-text>
            </v-card>
            <v-card v-else :color="imcResult.icon" dark>
                <v-card-text class="text-h1 white--text">
                    {{imcResult.imc}}
                </v-card-text>
                <v-card-text class="text-h4">
                    {{imcResult.text}}
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
    
    <div v-if="imcCorrect">
        <v-btn color="primary" @click="saveEncuesta()" >
            CONTINUAR
        </v-btn>
    </div>
  </div>
</template>

<script>
export default {
    
    data(){return{
        
        imc:{
            peso:0,
            altura:0,
        },
        respuesta:{
            id:null,
            pregunta_id : 125,
            opcion_ref : 438,
            valor: 0
        },
        validationRules:{
            altura: [
                v => !!v || "Este campo es necesario",
                v => (v && v >= 40) || "Su altura debe ser en centímetros",
                v => (v && v <= 250) || "Su altura debe ser en centímetros"
            ]
        }
    }},
    methods:{
        saveEncuesta(){
            this.respuesta.valor = this.imcResult.imc;
            const arr = [];
            arr.push(this.respuesta);
            this.$emit('fin', arr);
            this.$emit('data', this.imc);
        }
    },
    computed:{
        abdomenCorrect(){
            let resultado = false;
            if(!!this.abdomen){
                resultado = true;
            }else{ resultado = false } 
            if(this.abdomen && this.abdomen >= 40 && this.abdomen <= 300){
                resultado = true;
            }else{ resultado = false }  

            return resultado;
        },
        imcCorrect(){
            let responseAlt = false;
            let responsePes = false;
            if(this.imc.peso){
                if(this.imc.peso > 0 && this.imc.peso < 500){
                    responsePes = true;
                }
            }
            if(this.imc.altura){
                if(this.imc.altura >= 40 && this.imc.altura < 250){
                    responseAlt = true;
                }
            }
            return responseAlt && responsePes;
        },

        imcResult(){
            let response ={
                color:'red',
                icon: 'warning',
                text: 'Error',
                imc: 0
            }
            if(this.imcCorrect){
                let IMC__TOTAL = Math.round( 100 * this.imc.peso / ((this.imc.altura/100) * (this.imc.altura/100)))/100
                response.color = 'red';
                response.icon = 'warning';
                response.text = ' Cuidado, usted esta debajo de su peso ideal';

                if(IMC__TOTAL >= 18.5){
                    response.color = 'green';
                    response.icon = 'success';
                    response.text = ' Felicitaciones, usted esta en su peso ideal';
                }
                if(IMC__TOTAL >= 25){
                    response.color = 'yellow';
                    response.icon = 'warning';
                    response.text = ' Cuidado, sobrepeso';
                }
                if(IMC__TOTAL >= 30){
                    response.color = 'orange';
                    response.text = ' Cuidado, Obesidad';
                }
                if(IMC__TOTAL >= 35){
                    response.color = 'orange darken-2';
                    response.text = ' Cuidado, Obesidad II';
                }
                if(IMC__TOTAL >= 40){
                    response.color = 'red';
                    response.icon = 'error';
                    response.text = ' Cuidado, Obesidad III';
                }
                response.imc = IMC__TOTAL;
            }
            return response;
        }
    },
}
</script>

<style>

</style>