<template>
  <v-container>
      <div>
          <div class="text-h4">Semáforo de Estilo de Vida</div>
          <div class="">Por favor completar este formulario con datos veraces.  </div>
      </div>
      <div v-if="encuesta">
          <div>
              <v-row dense>
                  <v-col  cols="1" class="d-none d-md-block">
                      <v-btn :disabled="estructura.paso < 2" text width="100%" height="100%"  @click="setPaso(-1)">
                          <v-icon>mdi-arrow-left</v-icon>
                        </v-btn>
                  </v-col>
                  <v-col cols="12" md="10">
                    <v-card>
                        <v-toolbar color="blue" dark flat class="text-uppercase">
                           <v-btn
                           :disabled="estructura.paso < 2"  @click="setPaso(-1)"
                           icon
                           >
                           <v-icon>mdi-arrow-left</v-icon>
                           </v-btn>
                            <v-toolbar-title>{{etapa.area}}</v-toolbar-title>
                          
                        </v-toolbar>
                        <div>
                            <v-progress-linear height="10" :value="(estructura.paso/estructura.total)*100"></v-progress-linear>
                        </div>

                        <v-card-text v-if="etapa.tipo === 1">
                            <div class="text-h4 my-4 text-center">{{encuesta.preguntas.filter(x=>x.id === etapa.id)[0].nombre}}</div>
                        </v-card-text>
                        <v-divider  v-if="etapa.tipo === 1"></v-divider>
                        <v-card-actions  v-if="etapa.tipo === 1">
                            <v-chip-group v-model="estructura.ref_id" column  center-active active-class="purple white--text">
                                <v-chip 
                                 v-for="item in encuesta.preguntas.filter(x=>x.id === etapa.id)[0].opciones" 
                                 :key="item.id" :value="item.id"
                                 @click="setRespuesta(etapa.id, item)"
                                 >{{item.nombre}}</v-chip>
                            </v-chip-group>
                        </v-card-actions>

                        <v-card-text  v-if="etapa.tipo === 3">
                           Para terminar necesitamos algunos datos adicionales:
                           <div>
                              <v-row>
                                 <v-col cols="12" md="6">
                                    <v-text-field
                                       label="Peso (kg)"
                                       width="100%"
                                       hide-details="auto"
                                       type="number"
                                       v-model="remedio.peso"
                                    ></v-text-field>
                                 </v-col>
                                 <v-col cols="12" md="6">
                                    <v-checkbox v-model="remedio.renal" dense label="Poseo insuficiencia renal"></v-checkbox>
                                 </v-col>
                              </v-row>
                              
                           </div>
                        </v-card-text>

                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-btn v-if="etapa.tipo === 1"  @click="setPaso(1)" :disabled="!estructura.respuestas.filter(x=>x.pregunta_id === etapa.id)[0]" width="100%" color="primary">Continuar</v-btn>
                           <v-btn v-else width="100%" dark color="purple" @click="guardar()">Terminar encuesta</v-btn>
                        </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col cols="1" class="d-none d-md-block">
                        <v-btn dense text width="100%" height="100%"  @click="setPaso(1)">
                          <v-icon>mdi-arrow-right</v-icon>
                        </v-btn>
                  </v-col>
              </v-row>
          </div>
      </div>
      <div v-else>
          <div class="mx-auto text-center text-h5 my-2" style="max-width:800px">Buscando encuesta, por favor espere</div>
          <v-skeleton-loader
                class="mx-auto"
                max-width="800"
                type="card"
                ></v-skeleton-loader>
      </div>

      <v-dialog v-model="showResumen" :persistent="modalItem !== 1" max-width="800">
         <v-card v-if="modalItem === 1">
            <v-card-title primary-title>
               Por favor confirme sus respuestas
            </v-card-title>
            <v-card-text>
               <div v-for="enc in remedio.preliminar" :key="enc.id">
                  <v-divider></v-divider>
                  <div><b>{{enc.pregunta}}</b></div>
                  <div>{{enc.respuesta}}</div>
               </div>
            </v-card-text>
            <v-card-actions>
               <v-btn width="100%" color="primary" @click="guardarBD()">Guardar</v-btn>
            </v-card-actions>
         </v-card>

         <v-card v-if="modalItem === 2">
            <v-card-title class="text-center">Guardando encuesta, espere un momento por favor</v-card-title>
            <v-card-text class="text-center py-4">
               <v-progress-circular class="mx-auto" size="72" indeterminate color="purple"></v-progress-circular>
            </v-card-text>
         </v-card>
         
         <v-card v-if="modalItem === 3">
            <v-card-text>
               <RespuestaVida :resultado="remedio.respuesta" :encuesta="encuesta"/>
               <v-btn width="100%" to="/app/bienvenido" color="primary">Volver al inicio</v-btn>

            </v-card-text>
         </v-card>
      </v-dialog>

      <br />
      <br />
      <br />
  </v-container>
</template>

<script>

import encuestaService from '@/service/hopeEncuesta'
import EncuestaSave from  '@/model/app/pvs/Encuesta'
import RespuestaVida from '@/components/pvs/RespuestaVida'
export default {
   components:{RespuestaVida},
    data(){return{
        remedio:{
            numero:1,
            renal: false,
            peso: 0,
            preliminar:[],
            respuesta: {}
        },
        encuesta:null,
        showResumen:false,
        etapa:{},
        estructura:{
            paso : 1,
            total: 21,
            etapas: [
                {tipo: 1, area: 'Agua', id: 79, orden: 1},
                {tipo: 1, area: 'Agua', id: 80, orden: 2},
                {tipo: 1, area: 'Luz solar', id: 81, orden: 3},
                {tipo: 1, area: 'Luz solar', id: 82, orden: 4},
                {tipo: 1, area: 'Temperancia', id: 83, orden: 5},
                {tipo: 1, area: 'Temperancia', id: 84, orden: 6},
                {tipo: 1, area: 'Temperancia', id: 85, orden: 7},
                {tipo: 1, area: 'Temperancia', id: 86, orden: 8},
                {tipo: 1, area: 'Aire Puro', id: 87, orden: 9},
                {tipo: 1, area: 'Aire Puro', id: 88, orden: 10},
                {tipo: 1, area: 'Descanso', id: 89, orden: 11},
                {tipo: 1, area: 'Descanso', id: 90, orden: 12},
                {tipo: 1, area: 'Alimentación', id: 74, orden: 13},
                {tipo: 1, area: 'Alimentación', id: 75, orden: 14},
                {tipo: 1, area: 'Ejercicio', id: 76, orden: 15},
                {tipo: 1, area: 'Ejercicio', id: 77, orden: 16},
                {tipo: 1, area: 'Ejercicio', id: 78, orden: 17},
                {tipo: 1, area: 'Esperanza', id: 91, orden: 18},
                {tipo: 1, area: 'Esperanza', id: 92, orden: 19},
                {tipo: 1, area: 'Esperanza', id: 93, orden: 20},
                {tipo: 1, area: 'Esperanza', id: 94, orden: 21},
                {tipo: 3, area: 'Datos Generales', id: 1, orden: 22},
            ],
            respuestas:[],
            ref_id:1,
        },
         resp: new EncuestaSave(4, this.$store.state.core.user),
         modalItem: 1
    }},

    mounted(){
        setTimeout(() => {
            this.loadEncuesta();
            this.setEtapa();
        }, 1000);
    },

    computed:{

    },

    methods:{
        setPaso(step){
            if(this.estructura.paso < 2 && step < 0){
                step = 0;
            }
            this.estructura.paso = this.estructura.paso + step;
            this.setEtapa();
        },
        setRespuesta(pregunta, respuesta){
           respuesta.pregunta_id = pregunta;
           this.estructura.ref_id = respuesta.id;
           let indexFound = this.estructura.respuestas.findIndex(x=>x.pregunta_id===respuesta.pregunta_id);
           if(indexFound>-1){
              this.estructura.respuestas[indexFound] = respuesta;
           }
           else{
              this.estructura.respuestas.push(respuesta);
           }
        },
        setEtapa(){
            this.etapa = this.estructura.etapas.filter(x => x.orden === this.estructura.paso)[0] || {};
            this.estructura.respuestas
               .filter(x=>x.pregunta_id === this.etapa.id)
               .forEach(w=>this.estructura.ref_id = w.id);
        },
        loadEncuesta(){
            this.encuesta = null;
            encuestaService.getEncuesta(4)
            .then(response => {
               this.encuesta = response.data;
            })
        },
        guardar(){
           this.modalItem = 1;
           this.remedio.preliminar = [];
           this.resp.detalles = [];
           this.resp.insuficiencia_renal = this.remedio.renal;
           this.resp.peso = this.remedio.peso;
           this.estructura.respuestas.forEach(x=>{
              this.resp.detalles.push({
                 id: null,
                 valor: x.valor,
                 opcion_ref: x.id,
                 pregunta_id: x.pregunta_id
              });

              this.remedio.preliminar.push({
                 id: x.id,
                 pregunta: this.encuesta.preguntas.filter(z=>z.id === x.pregunta_id)[0].nombre,
                 respuesta: x.nombre
              })
           })
           this.showResumen = true;
        },
      
         guardarBD(){
            this.modalItem = 2;
            encuestaService.saveEncuestaRespuesta(this.resp)
            .then(response => {
               this.modalItem = 3;
               this.remedio.respuesta = response.data;
            })
         }
    }
}
</script>

<style>

</style>