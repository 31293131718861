export default class ResultadoCardio {
    encuesta = {}

    constructor(cuestionario){
        //let birthDate => ;
        this.encuesta = cuestionario;

    }

    getCardiovascular(){

        let parciales = [];
        let puntaje = 0;

        parciales.push({campo:"sexo", ref:this.encuesta.genero, puntaje: 0 });
            parciales.push({campo:"presion_tratamiento", ref:this.encuesta.presion_tratamiento, puntaje:   0});
            if(this.encuesta.genero === 'F'){
                if(this.encuesta.edad < 20) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 0 });
                else if(this.encuesta.edad <= 34) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: -7 });
                else if(this.encuesta.edad <= 39) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: -3 });
                else if(this.encuesta.edad <= 44) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: -0 });
                else if(this.encuesta.edad <= 49) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 3 });
                else if(this.encuesta.edad <= 54) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 6 });
                else if(this.encuesta.edad <= 59) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 8 });
                else if(this.encuesta.edad <= 64) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 10 });
                else if(this.encuesta.edad <= 69) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 12 });
                else if(this.encuesta.edad <= 74) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 14 });
                else puntaje += 16;

                if(this.encuesta.edad >=20 &&  this.encuesta.edad <=39){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 0 });
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  4});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  8});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  11});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  13});
                }
                if(this.encuesta.edad >=40 &&  this.encuesta.edad <=49){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  3});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  6});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  8});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  10});
                }
                if(this.encuesta.edad >=50 &&  this.encuesta.edad <=59){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 2 });
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 4 });
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  5});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 7 });
                }
                if(this.encuesta.edad >=60 &&  this.encuesta.edad <=69){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 1 });
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  2});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   3});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   4});
                }

                if(this.encuesta.edad >=70){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   1});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   1});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   2});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:   2});
                }

                
                if(this.encuesta.presion_tratamiento){
                    if(this.encuesta.presion_sistolica < 120) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   0});
                    else if(this.encuesta.presion_sistolica <= 129) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   1});
                    else if(this.encuesta.presion_sistolica <= 139) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   2});
                    else if(this.encuesta.presion_sistolica <= 159) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   3});
                    else parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   4});
                }else{
                    if(this.encuesta.presion_sistolica < 120) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   0});
                    else if(this.encuesta.presion_sistolica <= 129) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   3});
                    else if(this.encuesta.presion_sistolica <= 139) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   4});
                    else if(this.encuesta.presion_sistolica <= 159) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   5});
                    else parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:   6});
                }

                if(this.encuesta.fumador){
                    if(this.encuesta.edad < 20) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   0}) ;
                    else if(this.encuesta.edad <= 39) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   9});
                    else if(this.encuesta.edad <= 49) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   7});
                    else if(this.encuesta.edad <= 59) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   4});
                    else if(this.encuesta.edad <= 69) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   2});
                    else parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje: 1});
                }
                
                

               
            }else{
                //console.log("SEXO: VARON")
                if(this.encuesta.edad < 20) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: 0 });
                else if(this.encuesta.edad <= 34) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: -9 }) 
                else if(this.encuesta.edad <= 39) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: -4 }) 
                else if(this.encuesta.edad <= 44) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: -0 }) 
                else if(this.encuesta.edad <= 49) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +3 }) 
                else if(this.encuesta.edad <= 54) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +6 }) 
                else if(this.encuesta.edad <= 59) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +8 }) 
                else if(this.encuesta.edad <= 64) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +10 }) ;
                else if(this.encuesta.edad <= 69) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +11 }) ;
                else if(this.encuesta.edad <= 74) parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +12 }) ;
                else parciales.push({campo:"edad", ref:this.encuesta.edad, puntaje: +13 }) ;

                if(this.encuesta.edad >=20 &&  this.encuesta.edad <=39){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje: 0 });
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  4 });
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  7 });
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  9 });
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  11 });
                }
                if(this.encuesta.edad >=40 &&  this.encuesta.edad <=49){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  3});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  5});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  6});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  8});
                }
                if(this.encuesta.edad >=50 &&  this.encuesta.edad <=59){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  2});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  3});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  4});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  5});
                }
                if(this.encuesta.edad >=60 &&  this.encuesta.edad <=69){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  1});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  1});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  2});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  3});
                }

                if(this.encuesta.edad >=70){
                    if(this.encuesta.colesterol_total < 160) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=160 &&  this.encuesta.colesterol_total <=199) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=200 &&  this.encuesta.colesterol_total <=239) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  0});
                    else if(this.encuesta.colesterol_total >=240 &&  this.encuesta.colesterol_total <=279) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  1});
                    else if(this.encuesta.colesterol_total >= 280) parciales.push({campo:"col_total", ref:this.encuesta.colesterol_total, puntaje:  1});
                }

                if(this.encuesta.presion_tratamiento){
                    if(this.encuesta.presion_sistolica < 120) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  0});
                    else if(this.encuesta.presion_sistolica <= 129) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  0});
                    else if(this.encuesta.presion_sistolica <= 139) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  1});
                    else if(this.encuesta.presion_sistolica <= 159) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  1});
                    else parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  2});
                }else{
                    if(this.encuesta.presion_sistolica < 120) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  0});
                    else if(this.encuesta.presion_sistolica <= 129) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  1});
                    else if(this.encuesta.presion_sistolica <= 139) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  2});
                    else if(this.encuesta.presion_sistolica <= 159) parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  2});
                    else parciales.push({campo:"presion_sis", ref:this.encuesta.presion_sistolica, puntaje:  3});
                }

                if(this.encuesta.fumador){
                    if(this.encuesta.edad < 20) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   0});
                    else if(this.encuesta.edad <= 39) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   8});
                    else if(this.encuesta.edad <= 49) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   5});
                    else if(this.encuesta.edad <= 59) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   3});
                    else if(this.encuesta.edad <= 69) parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   1});
                    else parciales.push({campo:"fumador", ref:this.encuesta.fumador, puntaje:   1});
                }
            }
            
            if(this.encuesta.colesterol_hdl >= 60) parciales.push({campo:"col_hdl", ref:this.encuesta.colesterol_hdl, puntaje:   -1});
                else if(this.encuesta.colesterol_hdl >= 50) parciales.push({campo:"col_hdl", ref:this.encuesta.colesterol_hdl, puntaje:   0});
                else if(this.encuesta.colesterol_hdl >= 40) parciales.push({campo:"col_hdl", ref:this.encuesta.colesterol_hdl, puntaje:   1});
                else   parciales.push({campo:"col_hdl", ref:this.encuesta.colesterol_hdl, puntaje:   2});

            parciales.forEach(x=>puntaje += x.puntaje);
            console.table(parciales);
            //let probabilidad;
            let riesgo = "Su probabilidad de un accidente cardiovascular en los siguientes 10 años es baja.";
            let grado = 1;
            if(this.encuesta.genero === 'F'){
                if(puntaje > 19) {riesgo = " Su probabilidad de un accidente cardiovascular en los siguientes 10 años es media."; grado = 3;}
                if(puntaje > 22) {riesgo ="¡Atención! La probabilidad de un accidente cardiovascular en los siguientes 10 años es alta. "; grado = 5;}
            }else{
                if(puntaje > 11) {riesgo = " Su probabilidad de un accidente cardiovascular en los siguientes 10 años es media"; grado = 3;}
                if(puntaje > 15) {riesgo ="¡Atención! La probabilidad de un accidente cardiovascular en los siguientes 10 años es alta. "; grado = 5;}
            }

            return {mensaje:riesgo, puntaje:puntaje, grado:grado}

    }


    calcularDiabetes(){
        let puntaje = 0;
        let riesgo;
        let grado;

        
        if(this.encuesta.genero === 'F'){
            if(this.encuesta.abdomen > 88 ) { ++puntaje }
            if(this.encuesta.colesterol_hdl < 50 ) { ++puntaje }
        }
        if(this.encuesta.genero !== 'F'){
            if(this.encuesta.abdomen >= 94 ) { ++puntaje }
            if(this.encuesta.colesterol_hdl < 40 ) { ++puntaje }
        }

        if(parseInt(this.encuesta.trigliceridos) >=150) { ++puntaje }
        if(parseInt(this.encuesta.presion_sistolica) >=130 || parseInt(this.encuesta.presion_diastolica) >= 85) { ++puntaje }
        if(parseInt(this.encuesta.glucosa) >=100) { ++puntaje }

        if(puntaje >= 3){
            riesgo = '¡Atención! Existe el riesgo de tener el síndrome plurimetabólico.';
            grado = 5;
        }else{
            riesgo = 'Poco riesgo de tener el síndrome plurimetabólico.';
            grado = 1;
        }

        return {mensaje:riesgo, puntaje:puntaje, grado:grado}

    }

    calcularIMC(){
        let grado = 3;
        const metros = ( parseInt(this.encuesta.altura) * parseInt(this.encuesta.altura) ) / 10000;
        const puntaje =  Math.round( (parseInt(this.encuesta.peso)/metros) *100 )/100;


        let mensaje =  "¡Atención! Actualmente su Índice de Masa Corporal es "+puntaje+" y es bajo para su altura." 
        if(puntaje >= 18.5) { mensaje = "Felicitaciones! Actualmente su Índice de Masa Corporal es "+puntaje+" y es normal para su altura." ; grado = 1}
        if(puntaje > 25) { mensaje = "¡Atención! Actualmente su Índice de Masa Corporal es "+puntaje+" y está elevado (sobrepeso) para su altura." ; grado = 5}
        if(puntaje > 30) { mensaje = "¡Atención! Actualmente su Índice de Masa Corporal es "+puntaje+" y está está elevado (obesidad) para su altura."; grado = 5}
        return{
            puntaje : puntaje,
            mensaje: mensaje,
            grado:grado
        }
    }

   
    calularTrigliceridos(){
        if( this.encuesta.insulina){
            return {puntaje:0, mensaje:null, grado:null};
        }



        let grado = 5;
        let riesgo = "";
        const puntaje = Math.round((this.encuesta.trigliceridos/this.encuesta.colesterol_hdl)*100)/100; 
        
        if(puntaje >= 3){ riesgo = " ¡Atención! "; }
        riesgo += 'Aplicamos el índice Triglicéridos/HDL y su resultado fue '+puntaje+'. Por lo tanto usted ';
        if(puntaje < 3){ riesgo += " no "; grado = 1}
        riesgo += ' posee riesgo para tener el diabetes mellitus tipo 2.'

        if(puntaje >= 3 && this.encuesta.diabetes){
            return {riesgo:"Su índice de Triglicéridos/HDL es "+puntaje+". Identificamos que existe resistencia a la insulina", puntaje:puntaje, grado:5}
        }
        if(puntaje < 3 && this.encuesta.diabetes){
            return null
        }
       return {mensaje:riesgo, puntaje:puntaje, grado:grado}
    }

    


}
