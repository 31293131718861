<template>
  <v-container>
      <v-card>
          <v-card-title>
              <div>Citas programadas</div>
          </v-card-title>
          <v-card-text>
              <v-toolbar flat>
                <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                >
                    Hoy
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip color="purple" class="d-none d-sm-block" small dark>Citas virtuales</v-chip> 
                <v-chip color="primary" class="d-none d-sm-block" small>Citas presenciales</v-chip>
              </v-toolbar>
          </v-card-text>
          <v-card-text class="d-sm-none">
                <v-chip color="purple" small dark>Citas virtuales</v-chip> 
                <v-chip color="primary" small>Citas presenciales</v-chip>
          </v-card-text>
          <v-card-text>
              <v-calendar
                :events="citas"
                ref="calendar"
                v-model="calendar.focus"
                @click:event="showDetalle"
              >
              </v-calendar>
          </v-card-text>
      </v-card>

      <v-dialog
          v-model="dialog"
          max-width="500px"
          transition="dialog-transition"
      >
          <Cita :id="currentCita.id" />
      </v-dialog>
  </v-container>
</template>

<script>
import Cita from '@/components/servicios/Cita.vue'
import citaService from '@/service/servicios/citas'
import moment from 'moment'
export default {
    components:{Cita},
    data(){return{
        temp: {
            id: '135135135',
            medico: 'LEE LEE LEE',
            especialidad: 'FAMILIAR',
            fecha: '20210513',
            hini: '0930',
            hfin: '0950',
        },
        citas:[],
        citasAll:[],
        dialog: false,
        currentCita: {},
        calendar:{
            focus: ''
        }
    }},

    mounted(){
        this.citas = [];
        citaService.getCitas(this.$store.getters['core/cghID'])
        .then(response =>{
            this.citasAll = response.data;
            response.data.forEach(element => {
                this.citas.push(this.covertEvent(element));
            });
            
        })
    },

    methods:{
        covertEvent(cita){
            return {
                id: cita.id,
                name: cita.especialidad,
                start: moment(cita.dia + '' + cita.hora,  'YYYYMMDDHHmm').format().substring(0,19),
                end:  moment(cita.dia + '' + cita.fin,  'YYYYMMDDHHmm').format().substring(0,19),
                color: cita.virtual === '1' ? 'purple' : 'blue'
            }
        },
        showDetalle(evento){
            this.currentCita = evento.event;
            this.dialog = true;
            console.log(this.currentCita);
        },
        setToday () {
            this.calendar.focus = ''
        },
        prev () {
            this.$refs.calendar.prev()
        },
        next () {
            this.$refs.calendar.next()
        },
    }

}
</script>

<style>

</style>