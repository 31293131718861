<template>
  <div>
      <div>
          <v-row>
              <v-col cols="12" md="6">
                  <v-card dark :color="detalle.color" class="text-center">
                      <v-card-text>
                        <div class="text-h3">{{detalle.mensaje}}</div>
                      </v-card-text>
                  </v-card>
              </v-col>
              <v-col cols="12" md="6">
                  <v-card>
                      <div class="text-center purple pa-3" >
                          <v-icon color="white" x-large>mdi-information</v-icon>
                      </div>
                      <v-card-text class="text-center" v-if="resultado.insuficiencia_renal">{{detalle.agua}}</v-card-text>
                      <v-card-text class="text-center" v-if="!resultado.insuficiencia_renal && resultado.peso > 0">{{detalle.vasos}}</v-card-text>
                      <v-card-text class="text-center" v-if="!resultado.insuficiencia_renal && resultado.peso <= 0">No olvidar el consumo de agua pura</v-card-text>
                  </v-card>
              </v-col>
          </v-row>

          <div v-if="detalle.comentarios.length>0" class="text-left">
              <div class="text-h5 mt-4">Puntos a mejorar</div>
              <div v-for="comentario in detalle.comentarios" :key="comentario.id">
                  <div v-if="comentario.comentario" >
                      <div class="my-4">{{comentario.comentario}}</div>
                      <v-divider></v-divider>
                  </div>
              </div>
              
          </div>


      </div>
  </div>
</template>

<script>
import ResultadoVida from '@/model/app/pvs/ResultadoVida'
export default {

    props:['resultado', 'encuesta'],
    data(){return{
        detalle: new ResultadoVida(this.encuesta, this.resultado)
    }},
    mounted(){
        //this.detalle = new ResultadoVida(this.encuesta, this.resultado)
    },
    watch:{
        resultado: function(newVal){
            //console.log("estos son los resultados")
            //console.log(newVal)
            this.detalle = new ResultadoVida(this.encuesta, newVal)
        }
    }


}
</script>

<style>

</style>
