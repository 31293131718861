import HopeService from '@/service/hope'
const contextBase = 'servicios/cita/';

export default{
    getCitas(id){
        return HopeService().get(contextBase+'ultimas/'+id);
    },
    getCita(id){
        return HopeService().get(contextBase+'unique/'+id);
    },
}