<template>
  <v-container>
      <div>
          <div class="text-h4">Semáforo de Riesgo Cardiovascular</div>
          <div class="">Por favor completar este formulario con datos veraces. 
              En caso usted no tenga los exámenes solicitados para completar la información sobre el semáforo de riesgo cardiovascular, 
              podrá realizar su semáforo de su estilo de vida y posteriormente podrá realizar su evaluación de riesgo cardiovascular.</div>
      </div>
      <v-card class="my-3">
          <v-form v-model="validateForm" ref="cardioForm">
          <v-card-title  class="blue white--text">Información de salud</v-card-title>
          <v-divider></v-divider>
          <v-card-text>

            <v-row dense>
                <v-col cols="12" md="4">
                    <v-text-field type="number" :rules="[rules.required]" label="Peso (kg):" dense v-model="resp.peso" ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field type="number" :rules="[rules.required]" label="Altura (cm):" dense  v-model="resp.altura" ></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field type="number" :rules="[rules.required]" label="Perímetro abdominal (cm):" dense v-model="resp.abdomen" ></v-text-field>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="6">
                    <v-text-field label="Tensión Arterial" dense v-model="presion"
                        :rules="[rules.presure, rules.required]"
                        :messages="['Por ejemplo 120/90']"
                    ></v-text-field>
                </v-col>
                <v-col>
                   <v-checkbox label="Realiza tratamiento de hipertensión arterial" dense v-model="resp.presion_tratamiento" ></v-checkbox> 
                </v-col>
            </v-row>
            <v-row dense>
                <v-col>
                    <v-select dense label="Tipo de alimentación" v-model="resp.alimentacion" :items="alimentacionData">
                        </v-select>
                </v-col>
            </v-row>
            <v-row dense>
                <v-col cols="12" md="4">
                    <v-checkbox dense label="Insuficiencia Renal" v-model="resp.insuficiencia_renal"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox dense label="Insuficiencia cardiaca"  v-model="resp.insuficiencia_cardiaca"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox dense label="Fuma"  v-model="resp.fumador"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox dense label="Diabetes tipo 2"  v-model="resp.diabetes"></v-checkbox>
                </v-col>
                <v-col cols="12" md="4">
                    <v-checkbox dense label="Diabetes tipo 1 con uso de insulina"  v-model="resp.insulina"></v-checkbox>
                </v-col>
            </v-row>

          </v-card-text>
          <v-divider></v-divider>
          <v-card-title class="blue white--text" >Resultados de laboratorio</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
                <v-row dense>
                    <v-col cols="12" md="3">
                        <v-text-field type="number" :rules="[rules.required]" label="Colesterol HDL" dense v-model="resp.colesterol_hdl"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field type="number" :rules="[rules.required]" label="Colesterol Total" dense  v-model="resp.colesterol_total"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field type="number" :rules="[rules.required]" label="Triglicéridos" dense  v-model="resp.trigliceridos"></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                        <v-text-field type="number" :rules="[rules.required]" label="Glucosa en ayuno"  dense  v-model="resp.glucosa"></v-text-field>
                    </v-col>
                </v-row>
          </v-card-text>
          <v-card-text>
              <v-row>
                  <v-col class="text-center">
                      <v-btn color="red" text>Cancelar</v-btn>
                      <v-btn color="purple" dark @click="validarForm()">Guardar encuesta</v-btn>
                  </v-col>
              </v-row>
              <br />
              <br />
          </v-card-text>
          </v-form>
      </v-card>


      <v-dialog
          v-model="vista.show"
          max-width="800"
          transition="dialog-transition"
      >
         <v-card v-if="!vista.mostrar">
             <v-card-title primary-title>
                 Alerta
             </v-card-title>
             <v-card-text v-if="!vista.loader" class="text-center">
                 Seguro de guardar la información?
             </v-card-text>
             <v-card-text v-else class="text-center">
                 GUARDANDO INFORMACION, POR FAVOR ESPERE
                 <v-progress-circular indeterminate color="purple"></v-progress-circular>
             </v-card-text>
             <v-card-actions>
                 <v-btn :disabled="vista.loader" @click="guardarBD()" width="100%" dark color="warning">Guardar</v-btn>
             </v-card-actions>
         </v-card> 
         <v-card v-else>
             <v-card-text>
                 <RespuestaCardio v-if="vista.mostrar" :resultado="resultado" />
             </v-card-text>
            <v-card-actions>
                <v-btn dark width="100%"  to="/app/bienvenido" color="purple">cerrar</v-btn>
            </v-card-actions>
         
         </v-card>
      </v-dialog>
  </v-container>
</template>

<script>

import encuestaService from '@/service/hopeEncuesta'
import EncuestaSave from  '@/model/app/pvs/Encuesta'
import ResultadoCardio from  '@/model/app/pvs/ResultadoCardio'
import RespuestaCardio from '@/components/pvs/RespuestaCardio'
export default {
    components:{RespuestaCardio},
    data(){return{
        rules: {
          required: value => !!value || 'Dato necesario.',
          counter: value => value.length <= 20 || 'Max 20 characters',
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Correo electronico no valido.'
          },
          presure: value => {
              const pattern = /^\d{1,3}\/\d{1,3}$/
              return pattern.test(value) || 'Presión arterial no valido'
          }
        },
        presion:'',
        validateForm: false,
        resp: new EncuestaSave(5, this.$store.state.core.user),
        alimentacionData:[
            {value: '1', text: 'Carnívoro (Consume carnes)'}
            ,{value: '2', text: 'Flexitariano (sigue una dieta vegetariana, a veces consume carnes)'}
            ,{value: '3', text: 'Ovolactovegetariano (No consume carne, pescado, pollo, etc.)'}
            ,{value: '4', text: 'Vegetariano estricto (No consume nada de origen animal)'}
            ,{value: '5', text: 'Vegano (No consume nada de origen animal)'}
        ],
        vista:{
            show:false,
            consentimiento: true,
            loader: false,
            mostrar: false,
        },
        resultado:{},
        helperResultado: {}
    }},

    methods:{
        validarForm(){
            this.$refs.cardioForm.validate();
            if(this.validateForm){
                this.vista.show = true;
                this.vista.loader = false;
            }
        },
        guardarBD(){
            this.vista.loader = true;
            this.resp.presion_sistolica = this.presion.split("/")[0];
            this.resp.presion_diastolica = this.presion.split("/")[1];

            this.helperResultado = new ResultadoCardio(this.resp);

                this.resp.detalles.push({
                    id:null,
                    valor: this.helperResultado.getCardiovascular().puntaje,
                    opcion_ref: 0,
                    pregunta_id: 95
                })

                this.resp.detalles.push({
                    id:null,
                    valor: this.helperResultado.calcularDiabetes().puntaje,
                    opcion_ref: 0,
                    pregunta_id: 96
                })

                this.resp.detalles.push({
                    id:null,
                    valor: this.helperResultado.calcularIMC().puntaje,
                    opcion_ref: 0,
                    pregunta_id: 97
                })

                this.resp.detalles.push({
                    id:null,
                    valor: this.helperResultado.calularTrigliceridos().puntaje,
                    opcion_ref: 0,
                    pregunta_id: 98
                })





            encuestaService.saveEncuestaRespuesta(this.resp)
            .then(response => {
                this.resultado = response.data
                this.vista.mostrar = true;
            })
        }
    }
}
</script>

<style>

</style>