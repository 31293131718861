<template>
    <div class="footer white--text">
        <v-container>
                <v-row>
                    <v-col>
                        <div class="text-white">Ubicanos</div>
                        <div>
                            <table class="grey--text  mt-2">
                            <tr><td class="pr-2"><v-icon color="grey">mdi-map-marker</v-icon></td><td>Malecón Balta 956 Miraflores Lima, Perú</td></tr>
                            <tr><td><v-icon color="grey">mdi-web</v-icon></td><td>https://www.goodhope.org.pe</td></tr>
                            <tr><td><v-icon color="grey">mdi-email</v-icon></td><td>informes@goodhope.org.pe</td></tr>
                            <tr><td><v-icon color="grey">mdi-phone</v-icon></td><td>+51 1 6107300</td></tr>
                            </table>
                        </div>
                    </v-col>
                    <v-col>
                        <v-img class="mx-auto" max-width="400" contain src="https://www.goodhope.org.pe/wp-content/uploads/2018/09/logo.png" ></v-img>
                    </v-col>
                </v-row>
        </v-container>
    </div>
  
</template>

<script>
export default {

}
</script>

<style scoped>
    .footer{
        background-color: #333!important;
        border-top: rgb(214, 214, 214) solid 8px
    }
</style>