<template>
  <div>
          <v-list v-if="$store.getters['core/isLogin']" class="py-5">
              <v-list-item>
                  <v-list-item-avatar>
                      <v-icon>mdi-account</v-icon>
                  </v-list-item-avatar>
              </v-list-item>
              <v-list-item>
                  <v-list-item-content>
                      <v-list-item-title  class="wrap-text">{{$store.state.core.user.nombre}}</v-list-item-title>
                      <v-list-item-title  class="wrap-text">{{$store.state.core.user.apellidoPaterno}}</v-list-item-title>
                      <v-list-item-title  class="wrap-text">{{$store.state.core.user.apellidoMaterno}}</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
          </v-list>
          <v-divider></v-divider>
          <v-list nav dense>
              <v-list-group :value="true" prepend-icon="mdi-account-circle">
                <template v-slot:activator>
                <v-list-item-title>PROGRAMA VIDA SALUDABLE</v-list-item-title>
                </template>
                <v-list-item link to="/app/bienvenido">
                    <v-list-item-title>Inicio</v-list-item-title>
                    <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item link to="/app/pvs/distancia-mensual-all/encuesta">
                    <v-list-item-title>Seguimiento Mensual</v-list-item-title>
                    <v-list-item-icon><v-icon>mdi-calendar-month</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item link to="/app/pvs/distancia-semanal/encuesta">
                    <v-list-item-title>Seguimiento Semanal</v-list-item-title>
                    <v-list-item-icon><v-icon>mdi-calendar-week</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item link to="/app/pvs/cardio/encuesta">
                    <v-list-item-title>Semáforo Cardiovascular</v-list-item-title>
                    <v-list-item-icon><v-icon>mdi-heart-pulse</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item link to="/app/pvs/vida/encuesta">
                    <v-list-item-title>Vida Saludable</v-list-item-title>
                    <v-list-item-icon><v-icon>mdi-hand-heart</v-icon></v-list-item-icon>
                </v-list-item>
                <v-list-item link to="/app/pvs/historial">
                    <v-list-item-title>Historial</v-list-item-title>
                    <v-list-item-icon><v-icon>mdi-file-document-multiple</v-icon></v-list-item-icon>
                </v-list-item>
              </v-list-group>
                <v-list-item link to="/app/remedios/inicio">
                    <v-list-item-icon><v-icon>mdi-account-heart</v-icon></v-list-item-icon>
                    <v-list-item-title>REMEDIOS NATURALES</v-list-item-title>
                </v-list-item>

                <v-list-group v-if="prueba">
                    <template v-slot:activator>
                        <v-list-item-title>Servicios</v-list-item-title>
                    </template>
                    <v-list-item link to="/app/service/citas">
                        <v-list-item-title>Citas</v-list-item-title>
                        <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
                    </v-list-item>
                    <v-list-item link to="/app/service/mensualidad">
                        <v-list-item-title>Mensualidad</v-list-item-title>
                        <v-list-item-icon><v-icon>mdi-calendar</v-icon></v-list-item-icon>
                    </v-list-item>

                </v-list-group>
          </v-list>
            
  </div>
</template>

<script>
export default {
    data: () => ({
        selectedItem: 0,
        prueba:true
    }),
}
</script>

<style>

</style>