<template>
  <div>
      <v-container v-if="util.estado === 3">
          <div class="my-3">
              <div class="text-h4">Porque nos preocupamos por ti</div>
              <div class="">Queremos saber como has pasado este mes</div>
          </div>
          <v-stepper vertical v-model="util.paso">  
              <div v-for="paso in pasos" :key="paso.id">
                <v-stepper-step :step="paso.id" :editable="util.paso > 1" :complete="util.paso > paso.id">
                  {{paso.nombre}} 
                </v-stepper-step>
                <v-stepper-content :step="paso.id">
                    <div v-if="paso.tipo === 1">
                        <EMIMC     :pregunta="paso.preguntas[0]" @fin="addRespuestas" @data="setIMCInfo"/>
                    </div>
                    <div v-if="paso.tipo === 2">
                        <EMSimple  :pregunta="paso.preguntas[0]" @fin="addRespuestas"/>
                    </div>
                    <div v-if="paso.tipo === 3">
                        <EMDoble   :maestro="paso.preguntas[0]" :esclavo="paso.preguntas[1]" :salida="paso.break" @fin="addRespuestas"/>
                    </div>
                    <div v-if="paso.tipo === 4">
                        <EMTriple  :primera="paso.preguntas[0]" :segunda="paso.preguntas[1]" :tercera="paso.preguntas[2]" :salida="paso.break" @fin="addRespuestas"/>
                    </div>
                    <div v-if="paso.tipo === 5">
                        <EMAbdomen :pregunta="paso.preguntas[0]" @fin="addRespuestas" @data="setAbdomenInfo"/>
                    </div>
                </v-stepper-content>
              </div>
            
          </v-stepper>
          <div v-if="encuestaTerminada">
              <v-btn @click="preSave = true" x-large block color="purple" :disabled="util.guardando" class="white--text">
                  <span v-if="util.guardando">guardando tu encuesta</span>
                  <span v-else>Guardar encuesta</span>
              </v-btn>
              <v-dialog
                v-model="preSave"
                persistent
                max-width="500"
              >
                <RespuestaMensual :encuesta="encuesta" :respuesta="respuesta" @cancel="preSave = false" @ok="guardarCuestionario()" />
              </v-dialog>
          </div>

      </v-container>
      <v-container v-else>

        <v-card>
            <v-card-text class="text-center">
                <div v-if="util.estado === 2">
                    <div class="text-h4">¡Felicitaciones! <br /> Usted no tiene pendiente su <b> encuesta mensual</b></div>
                </div>
                <div v-if="util.estado === 4">
                    <div class="text-h4">¡Gracias! <br /> Su encuesta ha sido guardada</div>
                </div>
                <div v-if="util.estado === 0 || util.estado === 1">
                    <div>Buscando encuesta</div>
                    <div>
                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    </div> 
                </div>
            </v-card-text>
        </v-card>

      </v-container>
  </div>
</template>

<script>
import EncuestaService from '@/service/hopeEncuesta.js'
import CronicoService from '@/service/garantia/cronico.js'
import EncuestaSave from '@/model/app/pvs/Encuesta'

import EMIMC from '@/components/pvs/EMIMC.vue'
import EMAbdomen from '@/components/pvs/EMAbdomen.vue'
import EMSimple from '@/components/pvs/EMSimple.vue'
import EMDoble from '@/components/pvs/EMDoble.vue'
import EMTriple from '@/components/pvs/EMTriple.vue'
import RespuestaMensual from '@/components/pvs/RespuestaMensual'

export default {
    components:{EMIMC, EMAbdomen, EMDoble, EMTriple, EMSimple, RespuestaMensual},
    data(){return{
        util:{
            paso: 1,
            estado : 0, //0 Autorizando, 1: Buscando encuesta, 2: No autorizado, 3: Ready, 4: Gracias por guardar
            guardando : false
        },
        preSave: false,
        encuesta:{},
        respuesta:{},
        pasos:[
            {id:1,  tipo:1, preguntas:[], ref:[125], break:{ref:438, other:[]}, nombre: 'Cálculo de IMC'},
            {id:2,  tipo:5, preguntas:[], ref:[126], break:{ref:439, other:[]}, nombre: 'Circunferencia abdominal'},
            {id:3,  tipo:2, preguntas:[], ref:[127], break:{ref:440, other:[]}, nombre: 'Signo de alarma'},
            {id:4,  tipo:2, preguntas:[], ref:[128], break:{ref:441, other:[]}, nombre: 'Complicaciones'},
            {id:5,  tipo:3, preguntas:[], ref:[129, 130], break:{ref:445, other:[449]}, nombre: 'Sistema de apoyo'},
            {id:6,  tipo:2, preguntas:[], ref:[131], break:{ref:441, other:[]}, nombre: 'Medicación'},
            {id:7,  tipo:2, preguntas:[], ref:[132], break:{ref:453, other:[]}, nombre: 'Hemoglucotest'},
            {id:8,  tipo:3, preguntas:[], ref:[133, 134], break:{ref:455, other:[458], ref2: 508}, nombre: 'Presión arterial'},
            {id:9,  tipo:4, preguntas:[], ref:[135, 136, 137], break:{ref:460, other:[466, 468]}, nombre: 'Actividad física'},
            {id:10, tipo:3, preguntas:[], ref:[138, 139], break:{ref:473, other:[476]}, nombre: 'Alimentos saludables'},
            {id:11, tipo:3, preguntas:[], ref:[140, 141], break:{ref:479, other:[484]}, nombre: 'Alimentos no saludables'},
            {id:12, tipo:2, preguntas:[], ref:[142], break:{ref:0, other:[]}, nombre: 'Horas de sueño'},
            {id:13, tipo:4, preguntas:[], ref:[143, 144, 145], break:{ref:490, other:[495, 498]}, nombre: 'Bebidas gaseosas o azucaradas'},
            {id:14, tipo:2, preguntas:[], ref:[146], break:{ref:0, other:[]}, nombre: 'Agua pura'},
            ],
        autorizado:{},
    }},
    mounted(){
        this.util.estado = 0;
        this.respuesta = new EncuestaSave(8, this.$store.state.core.user);
        CronicoService.getPendiente(this.$store.getters['core/cghID'])
        .then(response=>{
            this.autorizado = response.data[0];
            if(this.autorizado.id === '0'){
                this.util.estado = 2;
            }else{
                this.util.estado = 1;
                EncuestaService.getEncuesta(8)
                .then(response=>{
                    this.util.estado = 3;
                    this.encuesta = response.data;
                    response.data.preguntas.forEach(e=> e.opciones = e.opciones.sort((a,b)=>a.orden - b.orden))
                    this.pasos.forEach(paso => {
                        paso.ref.forEach(ref => {
                            paso.preguntas.push(response.data.preguntas.find(p => parseInt(p.id) === ref))
                        })
                    })
                })
            }
        })
    },
    methods:{
        addRespuestas(bloque){
            this.util.paso = this.util.paso + 1;
            bloque.forEach(p => {
                const index = this.respuesta.detalles.findIndex(r => r.pregunta_id === p.pregunta_id);
                if(index === -1){
                    this.respuesta.detalles.push(p);
                }else{
                    this.respuesta.detalles[index] = p;
                }
            })
        },
        setIMCInfo(info){
            if(this.respuesta){
                this.respuesta.peso = info.peso;
                this.respuesta.altura = info.altura;
            }
        },
        setAbdomenInfo(info){
            if(this.respuesta){
                this.respuesta.abdomen = info.abdomen;
            }
        },
        guardarCuestionario(){
            this.preSave = false;
            this.util.guardando = true;
            //console.log("Guardando contenido")
            
            EncuestaService.saveEncuestaRespuesta(this.respuesta)
            .then(() => {
                this.util.guardando = false;
                this.util.estado = 4;
            });
            
        }
    },
    computed:{
        encuestaTerminada(){
            if(this.respuesta.detalles.length > 21){
                return true;
            }
            return false;
        }
    }
    
}
</script>

<style>

</style>