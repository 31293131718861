<template>
  <v-container>
      <div>
          <div class="text-h3 my-4">Mis encuestas realizadas</div>
      </div>
      <v-row>
          <v-col cols="12" md="6">
              <v-card max-height="300" style="overflow:auto">
                  <v-card-title primary-title>
                      Semáforo de riesgo Cardiovascular
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list v-if="cardio.loader && cardio.loadResult" two-line>
                      <template v-for="(item, index) in cardio.resultados" >
                        <v-list-item :key="item.id" @click="setVista(1, cardio.encuesta, item)">
                          <v-list-item-title>{{parseDate(item.fecha)}}</v-list-item-title>
                        </v-list-item>
                        <v-divider :key="index"></v-divider>
                      </template>
                     
                  </v-list>
                  <div v-else class="text-center pa-4">
                      <div>Cargando contenido</div>
                      <v-progress-circular indeterminate color="purple"></v-progress-circular>
                  </div>
              </v-card>
          </v-col>

          
          <v-col cols="12" md="6">
              <v-card max-height="300" style="overflow:auto">
                  <v-card-title primary-title>
                      Semáforo de Vida
                  </v-card-title>
                  <v-divider></v-divider>
                  <v-list v-if="vida.loader && vida.loadResult" two-line>
                      <template v-for="(item, index) in vida.resultados" >
                        <v-list-item :key="item.id"  @click="setVista(2, vida.encuesta, item)">
                            <v-list-item-icon><v-icon :color="getModeloVida(item).color">mdi-circle</v-icon></v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title>{{parseDate(item.fecha)}}</v-list-item-title>
                                <v-list-item-subtitle>{{getModeloVida(item).comentarios.length}} comentarios</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-divider :key="index"></v-divider>
                      </template>
                     
                  </v-list>
                  <div v-else class="text-center pa-4">
                      <div>Cargando contenido</div>
                      <v-progress-circular indeterminate color="purple"></v-progress-circular>
                  </div>
              </v-card>
          </v-col>


      </v-row>

      <v-row>
          <v-col>
              <v-card>
                  <v-card-title primary-title>
                      Cuidandote a distancia
                  </v-card-title>
                   
                  <v-simple-table>
                      <template>
                          <thead>
                              <th>Fecha</th>
                              <th>Paciente</th>
                              <th>Tipo</th>
                          </thead>
                          <tbody>
                              <tr v-for="mes in mensual.resultados" :key="mes.id">
                                  <td>{{parseDate(mes.fecha)}}</td>
                                  <td>{{mes.nombre}}</td>
                                  <td>Encuesta Mensual</td>
                              </tr>
                              <tr v-for="mes in semanal.resultados" :key="mes.id">
                                  <td>{{parseDate(mes.fecha)}}</td>
                                  <td>{{mes.nombre}}</td>
                                  <td>Encuesta Semanal</td>
                              </tr>
                          </tbody>
                      </template>
                  </v-simple-table>
              </v-card>
          </v-col>
      </v-row>

      <v-dialog
          v-model="vista.show"
          scrollable 
          max-width="800"
          transition="dialog-transition"
      >
      <v-card class="pt-2">
          <v-card-text>
              <RespuestaCardio v-if="vista.tipo === 1" :resultado="vista.resultado" :encuesta="vista.encuesta"/>
              <RespuestaVida v-if="vista.tipo === 2" :resultado="vista.resultado" :encuesta="vista.encuesta"/>
          </v-card-text>
          <v-card-actions>
              <v-btn dark width="100%" @click="vista.show = false" color="purple">cerrar</v-btn>
          </v-card-actions>
      </v-card>
          
      </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import encuestaService from '@/service/hopeEncuesta'


import RespuestaVida from '@/components/pvs/RespuestaVida'
import RespuestaCardio from '@/components/pvs/RespuestaCardio'


import ResultadoVida from '@/model/app/pvs/ResultadoVida'
export default {
    components:{RespuestaVida, RespuestaCardio},
    data(){return{
        cardio:{
            loader:false,
            loadResult:false,
            encuesta: {},
            resultados: [],
            ref:5
        },
        vida:{
            loader:false,
            loadResult:false,
            encuesta: {},
            resultados: [],
            ref:4
        },
        semanal:{
            loader:false,
            loadResult:false,
            encuesta: {},
            resultados: [],
            ref:6
        },
        mensual:{
            loader:false,
            loadResult:false,
            encuesta: {},
            resultados: [],
            ref:7
        },

        vista:{
            show: false,
            tipo:1,
            encuesta: {},
            resultado: {}
        }
    }},

    mounted(){
        //get All test models
        encuestaService.getEncuesta(this.cardio.ref).then(response=>{this.cardio.encuesta = response.data; this.cardio.loader = true});
        encuestaService.getEncuesta(this.vida.ref).then(response=>{this.vida.encuesta = response.data; this.vida.loader = true});
        encuestaService.getEncuesta(this.semanal.ref).then(response=>{this.semanal.encuesta = response.data; this.semanal.loader = true});
        encuestaService.getEncuesta(this.mensual.ref).then(response=>{this.mensual.encuesta = response.data; this.mensual.loader = true});
    
        // get historial test
        encuestaService.getEncuestas(this.cardio.ref).then(response=>{this.cardio.resultados = response.data; this.cardio.loadResult = true});
        encuestaService.getEncuestas(this.vida.ref).then(response=>{this.vida.resultados = response.data; this.vida.loadResult = true});
        encuestaService.getEncuestas(this.semanal.ref).then(response=>{this.semanal.resultados = response.data; this.semanal.loadResult = true});
        encuestaService.getEncuestas(this.mensual.ref).then(response=>{this.mensual.resultados = response.data; this.mensual.loadResult = true});
    
    },

    methods:{
         parseDate(fecha){
            return moment.parseZone(fecha).format('DD/MM/yyyy');
        },
        setVista(tipo, encuesta, resultado){
            this.vista.tipo = tipo;
            this.vista.encuesta = encuesta;
            this.vista.resultado = resultado;
            this.vista.show = true;
        },
        getModeloVida(item){
            return new ResultadoVida(this.vida.encuesta, item)
        }
    },
}
</script>

<style>

</style>