<template>
  <v-app>
    <v-navigation-drawer
      v-if="$store.getters['core/isLogin']"
      v-model="drawer"
      class="" 
      app
    >
      <Menu />
    </v-navigation-drawer>

    <v-app-bar app 
      class="blue" dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
      
      
      <v-toolbar-title>Hope</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn icon class=" text-center d-none d-md-block" @click="goHome()">
        <v-icon>mdi-home</v-icon>
      </v-btn>
      <v-btn disabled icon class=" text-center d-none d-md-block">
        <v-icon>mdi-cart</v-icon>
      </v-btn>
      <v-btn disabled icon class=" text-center d-none d-md-block">
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      <v-btn icon class="" @click="closeSession()">
        <v-icon class="">mdi-logout</v-icon>
      </v-btn>

    </v-app-bar>

    <v-main class="grey lighten-4">
      <router-view v-if="$store.getters['core/isLogin']"></router-view>
      <div v-else>
        Estamos preparando el sistema para ti
        <v-alert type="error" v-if="errorMessage">
          La sesión a Expirado, lo vamos a redireccionar a la pagina de 'Login' para que vuelva a iniciar sesión
        </v-alert>
      </div>
      <br />
      <br />
      <br />
    </v-main>

    <v-footer fixed class=" text-center d-block d-md-none" dark padless>
        <v-bottom-navigation active-class="bottom--navigation--active" color="blue-grey darken-4" dark shift v-model="selectApp">
          <v-btn @click="goHome()"><span>Inicio</span><v-icon class="mx-5">mdi-home</v-icon></v-btn>
          <v-btn disabled><span>Compras</span><v-icon class="mx-5">mdi-cart</v-icon></v-btn>
          <v-btn disabled><span>Notificaciones</span><v-icon class="mx-5">mdi-bell</v-icon></v-btn>
        </v-bottom-navigation> 
    </v-footer>
  </v-app>
</template>

<script>
import Menu from '@/components/plantilla/Menu.vue'

import securityService from '@/service/hopeSeguridad' 
export default {
  components:{Menu},
  data: () => ({ 
      drawer: null,
      selectApp: 0,
      errorMessage: false
    }),
  beforeCreate(){
    if(localStorage.getItem("hope")){
      if(!this.$store.getters['core/isLogin']){
        securityService.getUsuario()
          .then(response => {
              this.$store.commit('core/setUser', response.data[0]);
              this.$store.commit('core/setToken', localStorage.getItem("hope"));
          })
          .catch( error => {
            if(error.response){
              this.errorMessage = true;
              setTimeout(() => {
                this.$store.commit('core/logout');
                this.$router.push("/login");
              }, 5000);
            }
          })
      }
    }else{
      this.$router.push("/");
    }
  },
  methods:{
    closeSession(){
      this.$store.commit('core/logout');
      this.$router.push('/hola');
    },
    goHome(){
      this.$router.push('/app/bienvenido');
    }
  }
}
</script>

<style>
  .bottom--navigation--active{
    color: white !important;
  }
</style>