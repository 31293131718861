<template>
  <v-container >
      <v-sheet elevation="2" class=" mt-4" v-if="actual.video">
        <v-row dense >
          <v-col md="9" cols="12" class="pa-0">
            <v-sheet >
              <v-responsive :aspect-ratio="16/9" class="black">
                <iframe width="100%" height="100%" :src="actual.video" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
              </v-responsive>
              <div class="pa-4">
                <div class="text-h4">{{actual.title}}</div>
                <div class="">{{actual.text}}</div>
              </div>
              
            </v-sheet>
            
          </v-col>
          <v-col md="3" cols="12" class="black pa-0">
            <v-list max-height="100%" dark >
              <template v-for="(rem, index) in $store.state.remedio.info" >
                <v-list-item @click="actual = rem" class="text-truncate" two-line :key="rem.alt">
                  <v-list-item-content style="width:100%">
                    <v-list-item-title>{{rem.title}}</v-list-item-title>
                    <v-list-item-subtitle class="text-truncate">{{rem.text}}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider :key="index"></v-divider>
              </template>
              
            </v-list>
          </v-col>
        </v-row>
      </v-sheet>
  </v-container>
</template>

<script>
export default {
  data(){return{
    actual: this.$store.state.remedio.info[0]
  }}

}
</script>

<style>

</style>