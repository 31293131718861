<template>
  <v-container>
        <div class="text-h4 mb-3">Semáforo de Riesgo Cardiovascular</div>
     <div class="text-left">
         <v-simple-table>
             <template v-slot:default>
                 <tbody>
                    <tr v-for="res in respuestas" :key="res.id">
                            <td v-if="res.nivel === 1"><v-icon color="green">mdi-circle</v-icon></td>
                            <td v-if="res.nivel === 3"><v-icon color="warning">mdi-circle</v-icon></td>
                            <td v-if="res.nivel === 5"><v-icon color="red">mdi-circle</v-icon></td>
                            <td>{{res.mensaje}}</td>
                    </tr>
                 </tbody>
             </template>
             
         </v-simple-table>
          
      </div>
      <div class="mt-4">
          <div class="alert alert-warning h4">Si tiene un resultado en rojo o amarillo, recomendamos consultar con su médico.</div>
      </div>

  </v-container>
</template>

<script>

export default {
    props:['resultado'],
    data(){return{
        respuestas:[]
    }},
    mounted(){
        this.calcularRespuestas();
    },
    watch:{
        resultado(){
            this.calcularRespuestas()
        }
    },
    methods:{
        calcularRespuestas(){
            this.respuestas = [];
            let riesgo = "";
            let grado = 1;
            let id = 0; let puntaje = 0;
            //console.log(this.resultado.detalles)
            this.resultado.detalles.forEach(det => {
                riesgo = "";
                grado = 1;
                id = det.id;
                puntaje = parseFloat(det.valor);
                switch (det.pregunta_id){
                    case 95:
                        riesgo = "Su probabilidad de un accidente cardiovascular en los siguientes 10 años es baja."
                        grado = 1;
                        if(this.resultado.genero === 'F'){
                            if(puntaje > 19) {riesgo = " Su probabilidad de un accidente cardiovascular en los siguientes 10 años es media."; grado = 3;}
                            if(puntaje > 22) {riesgo ="¡Atención! La probabilidad de un accidente cardiovascular en los siguientes 10 años es alta. "; grado = 5;}
                        }else{
                            if(puntaje > 11) {riesgo = " Su probabilidad de un accidente cardiovascular en los siguientes 10 años es media."; grado = 3;}
                            if(puntaje > 15) {riesgo ="¡Atención! La probabilidad de un accidente cardiovascular en los siguientes 10 años es alta. "; grado = 5;}
                        }
                        this.respuestas.push({ id:id, mensaje:riesgo, nivel:grado });
                        break;

                    case 96:
                        if(puntaje >= 3){
                            riesgo = '¡Atención! Existe el riesgo de tener el síndrome plurimetabólico.';
                            grado = 5;
                        }else{
                            riesgo = 'Su probabilidad de tener el síndrome plurimetabólico es baja.';
                            grado = 1;
                        }
                        this.respuestas.push({ id:id, mensaje:riesgo, nivel:grado });
                        break;
                    case 97:
                        riesgo =  "¡Atención! Actualmente su Índice de Masa Corporal es "+puntaje+" y es bajo para su altura." 
                        if(puntaje >= 18.5) { riesgo = "Felicitaciones! Actualmente su Índice de Masa Corporal es "+puntaje+" y es normal para su altura." ; grado = 1}
                        if(puntaje > 25) { riesgo = "¡Atención! Actualmente su Índice de Masa Corporal es "+puntaje+" y está elevado (sobrepeso) para su altura." ; grado = 5}
                        if(puntaje > 30) { riesgo = "¡Atención! Actualmente su Índice de Masa Corporal es "+puntaje+" y está está elevado (obesidad) para su altura."; grado = 5}
                        this.respuestas.push({ id:id, mensaje:riesgo, nivel:grado });
                        break;
                    case 98:
                        if(puntaje >= 3){ riesgo = " ¡Atención! "; }
                        riesgo += 'Aplicamos el índice Triglicéridos/HDL y su resultado fue '+puntaje+'. ';
                        if(puntaje < 3){ 
                            riesgo += " Por lo tanto el riesgo para tener el diabetes mellitus tipo 2 es bajo. "; grado = 1}
                        else{
                            riesgo += "Por lo tanto existe un riesgo  para tener el diabetes mellitus tipo 2"; grado = 5;
                        }
                        //riesgo += ' posea riesgo para tener el diabetes mellitus tipo 2.'
                        if(!this.resultado.diabetes){
                            this.respuestas.push({ id:id, mensaje:riesgo, nivel:grado });
                        }
                        if(puntaje >= 3 && this.resultado.diabetes){
                            riesgo = "Su índice de Triglicéridos/HDL es "+puntaje+". Identificamos que existe resistencia a la insulina"; grado=5;
                            this.respuestas.push({ id:id, mensaje:riesgo, nivel:grado });
                        }
                        if(puntaje < 3 && this.resultado.diabetes){
                            //puntaje = grado;
                            
                        }
                        break;
                }

                
                    
            });

        }
    }
}
</script>

<style>

</style>