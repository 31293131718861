<template>
  <div>
    <div>
        <div>Por favor ingrese su circunferencia abdominal</div> 
        <div>
            <v-text-field :rules="validationRules.abdomen" filled  type="number" v-model="abdomen"></v-text-field>
        </div>
    </div>
        <div>
        <v-btn v-if="abdomenCorrect" color="primary" @click="finalizar()" >
            CONTINUAR
        </v-btn>
    </div>
  </div>
</template>

<script>
export default {
    data(){return{
        abdomen:'',
        validationRules:{
            abdomen: [
                v => !!v || "Este campo es necesario",
                v => (v && v >= 40) || "Su circunferencia abdominal debe ser en centímetros",
                v => (v && v <= 300) || "Su circunferencia abdominal debe ser en centímetros"
            ]
        },
        respuesta:{
            id:null,
            pregunta_id : 126,
            opcion_ref : 439,
            valor: 0
        },
    }},
    computed:{
        abdomenCorrect(){
            let resultado = false;
            if(!!this.abdomen){
                resultado = true;
            }else{ resultado = false } 
            if(this.abdomen && this.abdomen >= 40 && this.abdomen <= 300){
                resultado = true;
            }else{ resultado = false }  

            return resultado;
        }
    },
    methods:{
        finalizar(){
            this.respuesta.valor = this.abdomen;
            const arr = [];
            arr.push(this.respuesta);
            this.$emit('fin', arr);
            this.$emit('data', {abdomen:this.abdomen});
        }
    }
}
</script>

<style>

</style>