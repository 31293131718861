<template>
  <div>
    <v-card v-if="cita.id">
      <v-card-title :class="cita.color" class="white--text">{{cita.especialidad}}</v-card-title>
      <hr />
      <v-simple-table>
          <tbody>
            <tr>
                <th>Médico</th><td>{{cita.medico}}</td>
            </tr>
            <tr>
                <th>Fecha</th> <td>{{cita.fecha}}</td>
            </tr>
            <tr>
                <th>Hora</th>  <td>{{cita.hora}}</td>
            </tr>
          </tbody>
      </v-simple-table>
      <v-card-actions>
          <v-btn color="success" text>Cerrar</v-btn>
          <v-btn v-if="cita.meet" color="success" link :href="cita.meet" target="_blank">Zoom</v-btn>
      </v-card-actions>
    </v-card> 
    <v-card v-else>
        <v-card-text>
            <div v-if="loading" class="text-center">
                Estamos buscando la información
            </div>
            <div class="text-center" v-else>
                Tenemos un problema
            </div>
        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import citaService from '@/service/servicios/citas'
import moment from 'moment'
export default {
    props:['id'],
    data(){return{
        cita: {},
        loading: false
    }},
    mounted(){
        this.setCita();
    },
    methods:{
        setCita(){
            this.cita = {}
            this.loading = true;
            citaService.getCita(this.id)
            .then(response => {
                //console.log(response.data)
                this.cita = {
                    id : this.id,
                    especialidad: response.data.especialidad,
                    medico: response.data.medico,
                    fecha: moment(response.data.dia, 'YYYYMMMDD').format('DD/MM/YYYY'),
                    hora: moment(response.data.dia + ' ' + response.data.hora, 'YYYYMMMDD HHmm').format('hh:mm A'),
                    color: response.data.virtual === '1' ? 'purple': 'blue',
                    meet: response.data.linkPaciente,
                    pago: response.data.linkPago
                }
            })
        }
    },
    watch:{
        id:function(val){
            //console.log('nuevo valor: '+val)
            if(val){
                this.setCita();
            }else{
                this.cita = {};
            }
        }
    }
    
}
</script>

<style>

</style>