<template>
  <div>
      <v-container v-if="util.loading">
           <div class="my-3">
              <div class="text-h4">Porque nos preocupamos por ti</div>
              <div class="">Queremos saber como has pasado este mes</div>
          </div>
          <v-card>
              <div v-if="util.permisos" class="pa-4 text-center text-h4">Buscando información, por favor espere</div>
              <div v-else class="pa-4 text-center text-h4">¡Felicitaciones! <br /> no tiene pendiente la <b>encuesta mensual</b></div>
          </v-card>
      </v-container>
      <v-container v-else>
          <div class="my-3">
              <div class="text-h4">Porque nos preocupamos por ti</div>
              <div class="">Queremos saber como has pasado este mes</div>
          </div>

          <v-stepper vertical v-model="paso">
            <v-stepper-step step="1" :editable="paso > 1" :complete="paso > 1">
                  Calculo de IMC 
                  <small>Peso y Altura</small>
            </v-stepper-step>
            <v-stepper-content step="1">
                
                <v-row class="text-center">
                    <v-col md="6">
            
                    <div>ALTURA (cm)</div> 
                    <div>
                        <v-text-field :rules="validationRules.altura" filled  type="number"  v-model="imc.altura"></v-text-field>
                    </div>
                
                    <div>PESO (Kg)</div> 
                    <div>
                        <v-text-field filled  type="number" v-model="imc.peso"></v-text-field>
                    </div>
                
                    </v-col>
                    <v-col cols="12" md="6">
                        <v-card  v-if="!imcCorrect" color="warning" class="text-center" dark>
                            <v-card-text>
                                <v-icon class="mt-2 text-h2">mdi-alert-circle-outline</v-icon>
                            </v-card-text>
                            <v-card-text class="text-h4">
                                Por favor complete correctamente el formulario
                            </v-card-text>
                        </v-card>
                        <v-card v-else :color="imcResult.icon" dark>
                            <v-card-text class="text-h1 white--text">
                                {{imcResult.imc}}
                            </v-card-text>
                            <v-card-text class="text-h4">
                                {{imcResult.text}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                
                <div v-if="imcCorrect">
                    <v-btn  color="primary" @click="paso = 2" >
                        CONTINUAR
                    </v-btn>
                </div>

            </v-stepper-content>

             <v-stepper-step step="2" :editable="paso > 2" :complete="paso > 2">
                  Circunferencia Abdominal 
                  <small>en centrimetros</small>
            </v-stepper-step>
            <v-stepper-content step="2" >
                <div>
                    <div>Por favor ingrese su circunferencia abdominal</div> 
                    <div>
                        <v-text-field :rules="validationRules.abdomen" filled  type="number" v-model="abdomen"></v-text-field>
                    </div>
                </div>
                 <div>
                    <v-btn v-if="abdomenCorrect" color="primary" @click="paso = 3" >
                        CONTINUAR
                    </v-btn>
                </div>
            </v-stepper-content>  

            
            <v-stepper-step step="3" :editable="paso > 3" :complete="paso > 3">
                  Signo de alarma 
                  <small>Respuesta directa</small>
            </v-stepper-step>
            <v-stepper-content step="3">
                <div class="text-h6">   ¿Conoce los signos de alarma de su enfermedad?  </div>
                <div class="ml-3">
                    <v-radio-group v-model="signos">
                        <v-radio label="Sí, conozco los signos" :value="true" ></v-radio>
                        <v-radio label="No, desconozco los signos" :value="false" ></v-radio>
                    </v-radio-group>
                </div>
                 <div>
                    <v-btn  color="primary" @click="paso = 4" >
                        CONTINUAR
                    </v-btn>
                </div>
            </v-stepper-content>    

            
            <v-stepper-step step="4" :editable="paso > 4" :complete="paso > 4">
                  Complicaciones 
                  <small>Respuesta directa</small>
            </v-stepper-step>
            <v-stepper-content step="4">
                <div class="text-h6">¿Conoce las complicaciones de su enfermedad?  </div>
                <div class="ml-3">
                    <v-radio-group v-model="signos">
                        <v-radio label="Sí, conozco las complicaciones" :value="true" ></v-radio>
                        <v-radio label="No, desconozco las complicaciones" :value="false" ></v-radio>
                    </v-radio-group>
                </div>
                 <div>
                    <v-btn  color="primary" @click="paso = 5" >
                        CONTINUAR
                    </v-btn>
                </div>
            </v-stepper-content>  

            

            
            <v-stepper-step step="5" :editable="paso > 5" :complete="paso > 5">
                  Apoyo 
                  <small>Respuesta directa</small>
            </v-stepper-step>
            <v-stepper-content step="5">
                <div class="text-h6">¿Cuenta con sistema de apoyo?   </div>
                <div class="ml-3">
                    <v-radio-group v-model="apoyo">
                        <v-radio label="Sí, tengo un sistema de apoyo" :value="true" ></v-radio>
                        <v-radio label="No, no tengo un sistema de apoyo" :value="false" ></v-radio>
                    </v-radio-group>
                </div>
                <div v-if="apoyo">
                    <div>Indique que persona le apoya</div>
                    <v-radio-group v-model="apoyoTipo">
                        <v-radio label="Cuidador" value="1" ></v-radio>
                        <v-radio label="Persona a cargo" value="2" ></v-radio>
                        <v-radio label="Enfermera" value="3" ></v-radio>
                    </v-radio-group>
                </div>
                 <div>
                    <v-btn  color="primary" @click="terminarEncuesta()" >
                        TERMINAR ENCUESTA
                    </v-btn>
                </div>
            </v-stepper-content>      

          </v-stepper>
          <br />
      </v-container>

      <v-dialog
          v-model="confirmacion.show"
          max-width="800"
          transition="dialog-transition"
      >
          <v-card v-if="confirmacion.resumen">
              <v-card-title primary-title>
                  Confirmación
              </v-card-title>
              <v-card-text>¿Está seguro de guardar la encuesta?</v-card-text>
              <v-card-text>
                  <div><span>Altura</span>: <span>{{imc.altura}} cm</span> </div>
                  <div><span>Peso</span>: <span>{{imc.peso}} kg</span> </div>
                  <div><span>Abdomen</span>: <span>{{abdomen}} cm</span> </div>
                  <div><span>¿Conoce los signos de alarma de su enfermedad?</span>: 
                    <span v-if="signos">Si</span><span v-else>No</span> 
                  </div>
                  <div><span>¿Conoce las complicaciones de su enfermedad?</span>: 
                    <span v-if="complicaciones">Si</span><span v-else>No</span> 
                  </div>
                  <div><span>¿Cuenta con sistema de apoyo?</span>: 
                    <span v-if="apoyo">Si, 
                        <span v-if="apoyoTipo === '1'">Cuidado</span> 
                        <span v-if="apoyoTipo === '2'">Persona a cargo</span> 
                        <span v-if="apoyoTipo === '3'">Enfermera</span> 
                    </span><span v-else>No</span> 
                  </div>
              </v-card-text>
              <v-card-actions>
                  <v-btn width="100%" color="purple" dark @click="guardarBD()">Guardar</v-btn>
              </v-card-actions>
          </v-card>

          <v-card>
              <v-card-text>
                  <div>Guardando información</div>
                  <v-progress-circular indeterminate color="purple"></v-progress-circular>
              </v-card-text>
          </v-card>

          <v-card v-if="confirmacion.saved">
              <v-card-text>
                  <v-alert
                    type="success"
                    >Su encuesta se ha guardado</v-alert>
              </v-card-text>
              <v-card-actions>
                  <v-btn width="100%" to="/app/bienvenido" dark  color="purple">Volver</v-btn>
              </v-card-actions>
          </v-card> 


      </v-dialog>
  </div>
</template>

<script>

import encuestaService from '@/service/hopeEncuesta'
import EncuestaSave from '@/model/app/pvs/Encuesta'
import cronicoService from '@/service/garantia/cronico'
export default {
    data(){return{
        util:{
            loading : true,
            permisos: true,
        },
        paso: 1,
        imc:{
            peso:0,
            altura:0,
        },
        abdomen: null,
        signos:false,
        complicaciones: false,
        apoyo:false,
        apoyoTipo:0,
        encuesta: new EncuestaSave(7, this.$store.state.core.user),
        confirmacion:{
            show:false,
            resumen:true,
            saved: false,
            loader: false,
        },
        validationRules:{
            altura: [
                v => !!v || "Este campo es necesario",
                v => (v && v >= 40) || "Su altura debe ser en centímetros",
                v => (v && v <= 250) || "Su altura debe ser en centímetros"
            ],
            
            abdomen: [
                v => !!v || "Este campo es necesario",
                v => (v && v >= 40) || "Su circunferencia abdominal debe ser en centímetros",
                v => (v && v <= 300) || "Su circunferencia abdominal debe ser en centímetros"
            ]
        }
    }},
    mounted(){
        if(this.$store.getters["core/cghID"]){
            cronicoService.getPendiente(this.$store.getters["core/cghID"])
            .then(response => {
                response.data.forEach(enc => {
                    if(enc.tipo === "7"){
                        this.util.permisos = true;
                        this.util.loading = false;
                    }else{
                         this.util.permisos = false;
                    }
                });
            })
        }
    },
    computed:{
        abdomenCorrect(){
            let resultado = false;
            if(!!this.abdomen){
                resultado = true;
            }else{ resultado = false } 
            if(this.abdomen && this.abdomen >= 40 && this.abdomen <= 300){
                resultado = true;
            }else{ resultado = false }  

            return resultado;
        },
        imcCorrect(){
            let responseAlt = false;
            let responsePes = false;
            if(this.imc.peso){
                if(this.imc.peso > 0 && this.imc.peso < 500){
                    responsePes = true;
                }
            }
            if(this.imc.altura){
                if(this.imc.altura >= 40 && this.imc.altura < 250){
                    responseAlt = true;
                }
            }
            return responseAlt && responsePes;
        },

        imcResult(){
            let response ={
                color:'red',
                icon: 'warning',
                text: 'Error',
                imc: 0
            }
            if(this.imcCorrect){
                let IMC__TOTAL = Math.round( 100 * this.imc.peso / ((this.imc.altura/100) * (this.imc.altura/100)))/100
                response.color = 'red';
                response.icon = 'warning';
                response.text = ' Cuidado, usted esta debajo de su peso ideal';

                if(IMC__TOTAL >= 18.5){
                    response.color = 'green';
                    response.icon = 'success';
                    response.text = ' Felicitaciones, usted esta en su peso ideal';
                }
                if(IMC__TOTAL >= 25){
                    response.color = 'yellow';
                    response.icon = 'warning';
                    response.text = ' Cuidado, sobrepeso';
                }
                if(IMC__TOTAL >= 30){
                    response.color = 'orange';
                    response.text = ' Cuidado, Obesidad';
                }
                if(IMC__TOTAL >= 35){
                    response.color = 'orange darken-2';
                    response.text = ' Cuidado, Obesidad II';
                }
                if(IMC__TOTAL >= 40){
                    response.color = 'red';
                    response.icon = 'error';
                    response.text = ' Cuidado, Obesidad III';
                }
                response.imc = IMC__TOTAL;
            }
            return response;
        }
    },
    methods:{
        terminarEncuesta(){
            this.confirmacion.show = true;
            this.confirmacion.resumen = true;

        }
        ,
        guardarBD(){
            
            this.confirmacion.loader = true;
            this.confirmacion.resumen = false;
            this.confirmacion.saved = false;
            this.encuesta.peso = this.imc.peso;
            this.encuesta.altura = this.imc.altura;
            this.encuesta.abdomen = this.abdomen;

            /// pregunta 105 
            if(this.signos){
                this.encuesta.detalles.push({
                    id:null,
                    valor:1,
                    opcion_ref: 372,
                    pregunta_id: 105
                })
            }else{
                this.encuesta.detalles.push({
                    id:null,
                    valor: 0,
                    opcion_ref: 373,
                    pregunta_id: 105
                })
            }

            /// pregunta 106 
            if(this.complicaciones){
                this.encuesta.detalles.push({
                    id:null,
                    valor:1,
                    opcion_ref: 374,
                    pregunta_id: 106
                })
            }else{
                this.encuesta.detalles.push({
                    id:null,
                    valor: 0,
                    opcion_ref: 375,
                    pregunta_id: 106
                })
            }

            
            /// pregunta 107 
            if(this.apoyo){
                this.encuesta.detalles.push({
                    id:null,
                    valor:1,
                    opcion_ref: 376,
                    pregunta_id: 107
                })
            }else{
                this.encuesta.detalles.push({
                    id:null,
                    valor: 0,
                    opcion_ref: 377,
                    pregunta_id: 107
                })
            }


            /// pregunta 108 
            if(this.apoyoTipo === '1'){
                this.encuesta.detalles.push({
                    id:null,
                    valor:1,
                    opcion_ref: 378,
                    pregunta_id: 108
                })
            }else if(this.apoyoTipo === '2'){
                this.encuesta.detalles.push({
                    id:null,
                    valor:2,
                    opcion_ref: 379,
                    pregunta_id: 108
                })
            }else if(this.apoyoTipo === '3'){
                this.encuesta.detalles.push({
                    id:null,
                    valor:3,
                    opcion_ref: 380,
                    pregunta_id: 108
                })
            }else {
                this.encuesta.detalles.push({
                    id:null,
                    valor:4,
                    opcion_ref: 381,
                    pregunta_id: 108
                })
            }
            encuestaService.saveEncuestaRespuesta(this.encuesta)
            .then(response=>{
                this.confirmacion.saved = true;
                this.confirmacion.loader = false;
                console.log(response.data);
            })
            
        }
    }
}
</script>

<style scoped>
    input {
        text-align: center !important;
    }
</style>