<template>
  <div>
      <v-row>
          <v-col md="3" v-for="remedio in $store.state.remedio.info" :key="remedio.id">
                <v-card class="d-flex flex-column" @click="openVideo(remedio.url)">
                    <v-img :alt="remedio.alt" :src="remedio.src"></v-img>
                    <v-card-title>{{remedio.title}}</v-card-title>
                    <v-card-text class="flex-grow-1">{{remedio.text}}</v-card-text>
                </v-card>
          </v-col>
      </v-row>
      
               
  </div>
</template>

<script>
export default {
    data(){return{
        hoja:0
    }},
    methods:{
        openVideo(dir){
            window.open(dir, 'blank')
        }
    }
    
}
</script>

<style>

</style>