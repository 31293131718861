export default class ResultadoVida {
    resultado = {}
    encuesta = {}
    puntos = 0;
    color = "red";
    mensaje = "Estilo de Vida Insuficiente";
    agua = "Para conocer su consumo adecuado de agua procure a su médico";
    vasos = "";
    comentarios = [];

    constructor(pEncuesta, pResultado){
        this.encuesta = pEncuesta;
        this.resultado = pResultado;
        this.comentarios = [];
        if(this.resultado){
            this.puntos = this.resultado.detalles.reduce((total, valor)=> parseInt(total||0) + parseInt(valor.valor||0))
            if(this.puntos > 25){this.color="warning"; this.mensaje = "Estilo de Vida Regular"}
            if(this.puntos > 44){this.color="green"; this.mensaje = "Estilo de Vida Bueno"}
            if(this.puntos > 58){this.color="green"; this.mensaje = "Estilo de Vida Muy bueno"}
            if(this.puntos > 73){this.color="green"; this.mensaje = "Estilo de Vida Excelente"}

            let copos = Math.round(parseInt(this.resultado.peso)*30/250);
            this.vasos = 'Su consumo adecuado de agua para su peso actual es '+copos+' vasos de 250 ml por día';

                if(this.encuesta){
                    this.resultado.detalles.forEach(element => {
                        switch(element.pregunta_id){
                                case 73: case 83: case 87: case 90://console.log("error 0")
                                    if(parseInt(element.valor||0) < 3){// console.log("error 1")
                                        this.encuesta.preguntas.filter(x=>x.id === element.pregunta_id).forEach(y=>{ y.opciones.filter(z=>z.valor === element.valor).forEach(w=>this.comentarios.push(w));})
                                    }
                                    break;
                                case 84: case 85: case 86:
                                    if(parseInt(element.valor||0) < 1){//console.log("error 2")
                                        this.encuesta.preguntas.filter(x=>x.id === element.pregunta_id).forEach(y=>{ y.opciones.filter(z=>z.valor === element.valor).forEach(w=>this.comentarios.push(w));})
                                    }
                                    break;
                                case 91: case 92: case 93: case 94:
                                    if(parseInt(element.valor||0) < 2){//console.log("error 3")
                                        this.encuesta.preguntas.filter(x=>x.id === element.pregunta_id).forEach(y=>{ y.opciones.filter(z=>z.valor === element.valor).forEach(w=>this.comentarios.push(w));})
                                    }
                                    break;
                                default:
                                    this.encuesta.preguntas.filter(x=>x.id === element.pregunta_id).forEach(y=>{ y.opciones.filter(z=>z.valor === element.valor).forEach(w=>this.comentarios.push(w));})
                        }
                    });
                    this.comentarios = Array.from(new Set(this.comentarios));
                    this.comentarios = this.comentarios.filter(g=>g.comentario);
                }

            }
        



    }

}