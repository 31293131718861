<template>
  <v-app>
      <v-main>
          <v-sheet class="ma-auto text-center black--text" max-width="800">
            Estamos cargando el sistema, por favor espere
            <div>
                 <v-progress-linear
                    indeterminate
                    color="purple"
                    ></v-progress-linear>
            </div>
          </v-sheet>
         
      </v-main>
  </v-app>
</template>

<script>

import securityService from '@/service/hopeSeguridad'
export default {
    mounted(){  
        if(this.$route.query.hash){
            localStorage.setItem("hope", this.$route.query.hash)
            securityService.getUsuario()
                .then(response => {
                    this.$store.commit('core/setUser', response.data[0]);
                    this.$router.push("/app/bienvenido")
                })
                .catch(()=>{
                    this.$store.commit('core/logout');
                    alert("TOKEN NO VALIDO")
                    this.$router.push("/hola")
                })
        }
        else
        if(this.$route.query.recover){
            this.$router.push({path:"/recover/password", query:{recover:this.$route.query.recover}});
        }else 
        if(!localStorage.getItem("hope")){
            this.$router.push("/hola")
        }else{
            if(this.$store.getters['core/isLogin']){
                this.$router.push("/app/bienvenido")
            }else{
                securityService.getUsuario()
                .then(response => {
                    this.$store.commit('core/setUser', response.data[0]);
                    this.$router.push("/app/bienvenido")
                })
                .catch(()=>{
                    this.$store.commit('core/logout');
                    this.$router.push("/hola")
                })
            }
        }
    }
}
</script>

<style>

</style>