<template>
  <div>
      <v-row v-if="pregunta" class="mb-2">
          <v-col align-self="center">
              <div>
                   <v-card class="mx-auto text-center my-2 px-4 py-4 primary white--text" elevation="5" max-width="400">
                        <h4>{{pregunta.nombre}}</h4>
                    </v-card>
              </div>
          </v-col>
          <v-col>
              <div>
                <div class="mt-1 mb-1" v-for="opc in pregunta.opciones" :key="opc.id">
                    <v-btn color="secondary" elevation="2" rounded dark width="100%" class="mt-2"
                            :outlined="respuesta.opcion_ref !== opc.id"  
                            @click="finalizar(opc.id, opc.valor)">
                        {{opc.nombre}}
                    </v-btn>
                </div>
              </div>
          </v-col>
          <v-col></v-col>
      </v-row>
      <div v-else>Preparando pregunta</div>
  </div>
</template>

<script>
export default {
    props:['pregunta'],
    data(){return{
        respuesta:{
            id:null,
            pregunta_id : 0,
            opcion_ref : 0,
            valor: 0
        },
    }},
    methods:{
        finalizar(id, val){
            this.respuesta.pregunta_id = this.pregunta.id;
            this.respuesta.valor = val;
            this.respuesta.opcion_ref = id;
            const arr = [];
            arr.push(this.respuesta);
            this.$emit('fin', arr);
        }
    }
}
</script>

<style>

</style>