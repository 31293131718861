import HopeService from '@/service/hope'
export default{
    getEncuesta(id){
        if(!id){
            id = 1;
        }
        return HopeService().get('encuesta-libre/vida-saludable/'+id);
    },
    getEncuestas(id){
        return HopeService().get('encuesta-libre/vida-saludable-respuestas/'+id);
    },
    saveEncuesta(encuesta){
        return HopeService().post('encuesta-libre/vida-saludable', encuesta);
    },
    saveEncuestaRespuesta(encuesta){
        return HopeService().post('encuesta-libre/vida-saludable-respuesta', encuesta);
    }

}