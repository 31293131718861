<template>
  <div>
      <v-row v-if="maestro">
          <v-col align-self="center">
              <div>
                   <v-card class="mx-auto text-center my-2 px-4 py-4  primary white--text" elevation="5" max-width="400">
                        <h4>{{maestro.nombre}}</h4>
                    </v-card>
              </div>
          </v-col>
          <v-col>
              <div>Respuesta</div>
              <div>
                <div class="mt-1 mb-1" v-for="opc in maestro.opciones" :key="opc.id">
                    <v-btn color="secondary" elevation="2" rounded dark width="100%" class="mt-2"
                            :outlined="respuestaMaestro.opcion_ref !== opc.id"  
                            @click="addMaestro(opc.id, opc.valor)">
                        {{opc.nombre}}
                    </v-btn>
                </div>
              </div>
          </v-col>
          <v-col>
              <div v-if="viewSlave">{{esclavo.nombre}}</div>
              <div v-if="viewSlave">
                  <div class="mt-1 mb-1" v-for="opc in esclavo.opciones" :key="opc.id">
                    <v-btn v-if="opc.visible" color="secondary" elevation="2" rounded dark width="100%" class="mt-2"
                            :outlined="respuestaEsclavo.opcion_ref !== opc.id"  
                            @click="finalizar(opc.id, opc.valor)">
                        {{opc.nombre}}
                    </v-btn>
                  </div>
              </div>
          </v-col>
      </v-row>
      <div v-else>Preparando pregunta</div>
  </div>
</template>

<script>
export default {
    props:['maestro', 'esclavo', 'salida'],
    data(){return{
        viewSlave: false,
        respuestaMaestro:{
            id:null,
            pregunta_id : 0,
            opcion_ref : 0,
            valor: 0
        },
        respuestaEsclavo:{
            id:null,
            pregunta_id : 0,
            opcion_ref : 0,
            valor: 0
        },
    }},
    methods:{
        addMaestro(id, val){
            this.respuestaMaestro.pregunta_id = this.maestro.id;
            this.respuestaMaestro.valor = val;
            this.respuestaMaestro.opcion_ref = id;

            if(id === this.salida.ref || id === this.salida.ref2){
                this.viewSlave = false;
                this.esclavo.opciones.forEach(op => {
                    if(op.id === this.salida.other[0])
                    this.finalizar(op.id, op.valor);
                });
            }else{
                this.viewSlave = true;
            }
        },
        finalizar(id, val){
            this.respuestaEsclavo.pregunta_id = this.esclavo.id;
            this.respuestaEsclavo.valor = val;
            this.respuestaEsclavo.opcion_ref = id;

            const arr = [];
            arr.push(this.respuestaMaestro);
            arr.push(this.respuestaEsclavo);
            this.$emit('fin', arr);
        }
    }
}
</script>

<style>

</style>