import HopeService from '@/service/hope'
const contextBase = 'garantia/cronico/';
//const contextBase2 = 'garantia2/labo/';
export default{
    getSemestral(){
        return HopeService().get(contextBase+'semestral');
    },
    getSemanal(){
        return HopeService().get(contextBase+'semanal');
    },
    getEncuesta(id){
        return HopeService().get(contextBase+'encuesta/'+id);
    },
    getPendiente(idCGH){
        return HopeService().get(contextBase+'pendiente/'+idCGH);
    },
    getLabo(id){
        return HopeService().get('garantia2/labo/getlabo/'+id);
    },
}