<template>
  <v-container>
      <div>
          <v-text-field
              v-model="anio"
              label="Indique el año por favor"
              outlined
          ></v-text-field>
      </div>
      <div>
          <v-simple-table >
            <template v-slot:default>
                <thead>
                    <tr><th>Periodo</th><th>Detalle</th><th>Monto</th></tr>
                </thead>
                <tbody>
                    <tr v-for="mes in mensualidades" :key="mes.id">
                        <td>{{mes.periodo}}</td>
                        <td>{{mes.detalle}}</td>
                        <td>{{mes.importe}}</td>
                    </tr>
                </tbody>
            </template>
          </v-simple-table>
      </div>
  </v-container>
</template>

<script>
import mensualidadService from '@/service/servicios/mensualidades'
export default {
    data(){return{
        mensualidades: [],
        anio: '2021'
    }},

    mounted(){
        this.buscarMensualidad()
    },
    
    methods : {
        buscarMensualidad(){
            mensualidadService.getMensualidadAnio(this.anio, this.$store.getters['core/cghID'])
            .then(response=>{
                this.mensualidades = response.data;
            })

        }
    },

    watch:{
        anio:function(newVal){
            if(newVal){
                if(newVal.length === 4){
                    this.buscarMensualidad();
                }
            }
        }
    }
}
</script>

<style>

</style>