<template>
    <div>
        <div>
            <div class="titleBG">
                
                <v-container>
                    
                    <div>
                        <div class="mt-3 white--text text-h3 font-weight-thin">Programa Vida Saludable Virtual (PVS - Virtual)</div>
                        <div class="mt-2 blue--text text--lighten-2 text-h5">Semáforo de Riesgo de Cardiovascular y del Estilo de Vida</div>
                        <div class="mt-2 white--text mb-3 font-weight-light">
                            Bienvenido a nuestro Programa Vida Saludable Virtual <b>TOTALMENTE GRATUITO</b>, según los exámenes y datos informados por usted,
                            iremos mostrando cómo está su semáforo de riesgo de sufrir un infarto en los próximos 10 años (Score de Riesgo de Framingham),
                            además evaluaremos su estilo de vida con el cuestionario Q8RN (Ocho Remedios Naturales).
                            En caso usted no tenga los exámenes solicitados para completar la información sobre el semáforo de riesgo cardiovascular,
                            podrá realizar su semáforo de su estilo de vida y posteriormente podrá realizar su evaluación de riesgo cardiovascular.
                        </div>
                    </div>

                    <v-row>
                        <v-col cols="12" md="6" lg="4">
                            <v-card>
                                <v-img height="200" src="@/assets/app/pvs/semaforo_cardio.jpg"></v-img>
                                <v-card-title style="word-break:keep-all;" class="text-h5 text-uppercase red--text text-center mx-3">
                                    Semáforo de riesgo cardiovascular
                                </v-card-title>
                                <v-card-actions >
                                    <v-btn to="/app/pvs/cardio/encuesta" class="mx-auto" color="red" dark width="100%"> Iniciar Test</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col cols="12" md="6" lg="4">
                            <v-card>
                                <v-img height="200" src="@/assets/app/pvs/semaforo_vida.jpg"></v-img>
                                <v-card-title style="word-break:keep-all;" class="text-h5 text-uppercase green--text text-center mx-3">
                                    Semáforo de estilo de vida
                                </v-card-title>
                                <v-card-actions >
                                    <v-btn to="/app/pvs/vida/encuesta" class="mx-auto" color="green" dark width="100%"> Iniciar Test</v-btn>
                                
                                </v-card-actions>
                            </v-card> 
                        </v-col>
                        <v-col v-if="isCronico" cols="12" md="6" lg="4">
                            <v-card>
                                <v-img height="200" src="@/assets/app/pvs/cuidandote_distancia.jpg"></v-img>
                                <v-card-title style="word-break:keep-all;" class="text-h5 text-uppercase black--text text-center mx-3">
                                    Programa cuidándote a distancia
                                </v-card-title>
                                <v-card-actions >
                                    <v-btn dark class="mx-auto" width="100%" color="black" @click="loginRedirect(urlCronico)"> Iniciar Test</v-btn>
                                    
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>

                    <div class="mt-3">
                        <div class="font-weight-bold text-caption">Aplica unicamente para el PVS</div>
                            <ul class="text-caption">
                                <li>Abdala, G., Meira, M., Isayama, R., Rodrigo, G., Wataya, R., &amp; Tertuliano, I. 2018. 
                                    VALIDAÇÃO DO QUESTIONÁRIO OITO REMÉDIOS NATURAIS – Q8RN – VERSÃO ADULTO. Life Style, 5(2), 109-134. https://doi.org/10.19141/2237-3756.lifestyle.v5.n2.p109-134</li>
                                <li>Young KA, Maturu A, Lorenzo C, et al. La relación de triglicéridos a colesterol unido a lipoproteínas de alta densidad (TG / HDL-C) 
                                    como predictor de resistencia a la insulina, función de las células β y diabetes en hispanos y afroamericanos. J Complicaciones de la diabetes.
                                     2019; 33 (2): 118-122. doi: 10.1016 / j.jdiacomp.2018.10.018</li>
                                <li>Borges, Jairo L. O coração saudável: como cuidar do órgão que mantém a vida. 1ª ed: Casa Publicadora Brasileira. 2004</li>
                                <li>https://q8rn.com/</li>
                            </ul>
                    </div>

                    <v-dialog v-model="openAlert" max-width="600">
                        <v-card>
                            <v-toolbar
                                color="primary"
                                dark
                                >ATENCIÓN</v-toolbar>
                            <v-card-text>
                                <div class="pt-3 text-h6 mx-auto">Necesitas iniciar sesión para utilizar esta opción</div>
                            </v-card-text>
                            <v-card-actions class="justify-end">
                                <v-btn
                                    text
                                    @click="openAlert = false"
                                >Cerrar</v-btn>     
                                <v-btn class="blue"
                                    dark
                                    @click="goLogin()"
                                >Iniciar Sesión</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>



                </v-container>
            </div>
            <div class="blue darken-3">
                <v-container>
                    <div class="my-3 white--text text-h4 text-center font-weight-light">¡Hola, haz click para saber mas sobre los 8 remedios naturales!</div>
                    <Ocho />
                </v-container>
            </div>
            <div >
               
                     <Pie />
               
            </div>
            
        </div>
    </div>
</template>

<script>
import Pie from '@/components/plantilla/PieCGH.vue'
import Ocho from '@/components/plantilla/OchoRemedios.vue'
import cronicoService from '@/service/garantia/cronico'
export default {
    components:{Pie, Ocho},
    data(){return{
        openAlert : false,
        isCronico: false,
        urlCronico: '',
    }},
    methods:{
        loginRedirect(url){
            this.$router.push(url)
            
        },
        loginAlone(){
            this.$store.state.login.redirect = null;
            this.goLogin()
        },
        goLogin(){
            this.openAlert = false;
            this.$router.push('/login')
        }
    },
    mounted(){
        this.urlCronico ="";
        this.isCronico = false;
        //console.log(this.$store.getters["core/cghID"])
        if(this.$store.getters["core/cghID"]){
            cronicoService.getPendiente(this.$store.getters["core/cghID"])
            .then(response => {
                response.data.forEach(enc => {
                    if(enc.tipo === "6"){
                        this.isCronico = true;
                        this.urlCronico = "/app/pvs/distancia-semanal/encuesta"
                    }
                    if(enc.tipo === "8"){
                        this.isCronico = true;
                        this.urlCronico = "/app/pvs/distancia-mensual-all/encuesta"
                    }
                });
            })
        }
    }
}
</script>

<style scoped>
    .borderRow{
        -webkit-box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.41); 
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.41);
    }
    .titleBG{
        background-image: url('~@/assets/plantilla/familia_blue.jpg'); 
        background-position: center 20%;
        background-repeat: repeat;
        color: white;
    }
</style>