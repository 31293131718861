export default class EncuestaRespuesta {
    registro = 1;
    usuario = 0;
    nombre = '';
    nacimiento = new Date();
    pais = 'PER';
    ciudad = 'LIMA';
    religion = null;
    campo = null;
    instruccion = null;
    peso = 0;
    altura = 0;
    abdomen = 0;
    presion_sistolica = 0;
    presion_diastolica = 0;
    alimentacion = 0;
    colesterol_hdl = 0;
    colesterol_ldl = 0;
    colesterol_total = 0;
    trigliceridos = 0;
    glucosa = 0;
    edad = 0;
    fumador = false;
    presion_tratamiento = false;
    insuficiencia_renal = false;
    insuficiencia_cardiaca = false;
    diabetes = false;
    insulina = false;
    genero = 'M';
    documento = '';
    correo = '';
    celular = '';
    detalles = [];
    preguntas = [];

    constructor(tipo, usuario){
        //let birthDate => ;
        this.registro = tipo;
        this.usuario = usuario.id;
        this.nombre = usuario.nombre + ' ' + usuario.apellidoPaterno + ' ' + usuario.apellidoMaterno;
        this.nacimiento = usuario.nacimiento;
        this.genero = usuario.sexo;
        this.documento = usuario.documentoNumero;
        this.edad =  Math.floor((new Date() - new Date(this.nacimiento).getTime()) / 3.15576e+10);

    }
}