<template>
  <div>
      <v-row v-if="primera">
          <v-col align-self="center">
              <div>
                <v-card class="mx-auto text-center my-2 px-4 py-4 primary white--text" elevation="5" max-width="400">
                    <h4>{{primera.nombre}}</h4>
                </v-card>
                <div class="mt-1 mb-1" v-for="opc in primera.opciones" :key="opc.id">
                    <v-btn color="secondary" elevation="2" rounded dark width="100%" class="mt-2"
                            :outlined="primeraResp.opcion_ref !== opc.id"  
                            @click="addMaestro(opc.id, opc.valor)">
                        {{opc.nombre}}
                    </v-btn>
                </div>
              </div>
          </v-col>
          <v-col>
              <div>{{segunda.nombre}}</div>
              <div>
                <div class="mt-1 mb-1" v-for="opc in segunda.opciones" :key="opc.id">
                    <v-btn v-if="opc.visible" color="secondary" elevation="2" rounded block class="mt-2"
                            :outlined="segundaResp.opcion_ref !== opc.id"  :disabled="enableQuest"
                            @click="addSegundo(opc.id, opc.valor)">
                        {{opc.nombre}}
                    </v-btn>
                </div>
              </div>
          </v-col>
          <v-col>
              <div>{{tercera.nombre}}</div>
              <div>
                  <div class="mt-1 mb-1" v-for="opc in tercera.opciones" :key="opc.id">
                    <v-btn v-if="opc.visible" color="secondary" elevation="2" rounded block class="mt-2"
                            :outlined="terceraResp.opcion_ref !== opc.id"   :disabled="enableQuest"
                            @click="finalizar(opc.id, opc.valor)">
                        {{opc.nombre}}
                    </v-btn>
                  </div>
              </div>
          </v-col>
      </v-row>
      <div v-else>Preparando pregunta</div>
  </div>
</template>

<script>
export default {
    props:['primera', 'segunda', 'tercera', 'salida'],
    data(){return{
        enableQuest: true,
        primeraResp:{
            id:null,
            pregunta_id : 0,
            opcion_ref : 0,
            valor: 0
        },
        segundaResp:{
            id:null,
            pregunta_id : 0,
            opcion_ref : 0,
            valor: 0
        },
        terceraResp:{
            id:null,
            pregunta_id : 0,
            opcion_ref : 0,
            valor: 0
        }
    }}, 
    methods:{
        addMaestro(id, val){
            this.primeraResp.pregunta_id = this.primera.id;
            this.primeraResp.valor = val;
            this.primeraResp.opcion_ref = id;

            if(id === this.salida.ref){
                this.enableQuest = true;
                this.segunda.opciones.forEach(op => {
                    if(op.id === this.salida.other[0])
                    this.addSegundo(op.id, op.valor);
                });
                this.tercera.opciones.forEach(op => {
                    if(op.id === this.salida.other[1])
                    this.finalizar(op.id, op.valor);
                });
            }else{
                this.enableQuest = false;
            }
        },
        addSegundo(id, val){
            this.segundaResp.pregunta_id = this.segunda.id;
            this.segundaResp.valor = val;
            this.segundaResp.opcion_ref = id;
        },
        finalizar(id, val){
            this.terceraResp.pregunta_id = this.tercera.id;
            this.terceraResp.valor = val;
            this.terceraResp.opcion_ref = id;

            const arr = [];
            arr.push(this.primeraResp);
            arr.push(this.segundaResp);
            arr.push(this.terceraResp);
            this.$emit('fin', arr);
        }
    }
}
</script>

<style>

</style>