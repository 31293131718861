<template>
  <div>
      <div v-if="encuesta.id">
          <v-card>
              <v-card-text>
             <div>
              <h3>{{encuesta.descripcion}}</h3>
            </div>
            <hr />
            <div v-for="enc in encuesta.preguntas" :key="enc.id">
                <div><h5>{{enc.nombre}}</h5></div>
                <div>{{getResp(enc.id)}}</div>
            </div>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                    color="red darken-1"
                    text
                    @click="$emit('cancel')"
                >
                    Cancelar
                </v-btn>
                <v-btn
                    color="green darken-1"
                    outlined
                    @click="$emit('ok')"
                >
                    Confirmar y guardar
                </v-btn>
            </v-card-actions>
          </v-card>
         
      </div>
  </div>
</template>

<script>
export default {
    props:['encuesta', 'respuesta'],
    methods:{
        getResp(preg){
            //let comentario = '';
            const sol = this.respuesta.detalles.find(a => a.pregunta_id === preg);
            if(sol){
                if(preg === 125 || preg === 126){
                    return sol.valor;
                }
                return this.getNombreOpcion(preg, sol.opcion_ref)
            }
        },
        getNombreOpcion(preg, id){
           return this.encuesta.preguntas.find(a=>a.id === preg).opciones.find(b=>b.id===id).nombre;
        }
    }
}
</script>

<style>

</style>